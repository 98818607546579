import { Box, Typography } from '@mui/material';
import IgnorePadding from 'components/wrappers/IgnorePadding';
import React from 'react';
import { cardPalette } from 'styles/theme/palette';
import LinkButton from '../LinkButton';
import { ArrowRightIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';

interface ContractCardBottomControlProps {
    contractUuid: string;
    hasBeenRenewed?: boolean;
}

const ContractCardBottomControl = ({ contractUuid, hasBeenRenewed }: ContractCardBottomControlProps) => {
    const { t } = useTranslation('contract-card');
    return (
        <IgnorePadding ignoreBottom>
            <Box
                sx={{
                    marginTop: 4,
                    height: 58,
                    backgroundColor: cardPalette.stackBgColor,
                    borderTop: `1px solid ${cardPalette.border}`,
                }}
            >
                <LinkButton
                    sx={{ width: 'fit-content' }}
                    variant="text"
                    to={`/customer/contract-details/${contractUuid}`}
                    fullWidth
                >
                    <Typography
                        sx={{ display: 'flex' }}
                        color={(theme) => theme.palette.text.primary}
                        variant="bodySmallEmphasized"
                    >
                        {hasBeenRenewed ? t('SAVED_OFFERS') : t('OFFERS')}
                        <ArrowRightIcon />
                    </Typography>
                </LinkButton>
            </Box>
        </IgnorePadding>
    );
};

export default ContractCardBottomControl;
