import { Box, Stack } from '@mui/material';
import { Fragment } from 'react';
import Connector from './Connector';
import Step from './Step/Step';
import { styled } from '@mui/system';
import Show from 'components/wrappers/Show';

interface DCPStepperProps {
    activeStep: number;
    steps: string[];
}

const StepperStack = styled(Stack)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 'auto',
    padding: '0 2rem',
});

const DCPStepper = ({ activeStep, steps }: DCPStepperProps) => {
    return (
        <Box>
            <StepperStack gap={{ xs: 0.5, sm: 2, md: 3 }}>
                {steps.map((step, index) => (
                    <Fragment key={step}>
                        <Step
                            step={step}
                            index={index}
                            isActive={index === activeStep}
                            isCompleted={index < activeStep}
                            isLastStep={index === steps.length - 1}
                        />
                        <Show condition={index !== steps.length - 1}>
                            <Connector index={index} activeStep={activeStep} />
                        </Show>
                    </Fragment>
                ))}
            </StepperStack>
        </Box>
    );
};

export default DCPStepper;
