import { currentUserActiveContractsMock, openOffersMock, renewedContractList } from 'data/mocks/contracts-mock';
import { useMemo } from 'react';

const useContracts = () => {
    const renewedContractsMap: Record<string, boolean> = useMemo(
        () => renewedContractList.reduce((acc, contract) => ({ ...acc, [contract.uuid]: true }), {}),
        [],
    );

    const activeContracts = useMemo(
        () =>
            currentUserActiveContractsMock.map((contract) => {
                return {
                    ...contract,
                    hasBeenRenewed: renewedContractsMap[contract.uuid] || false,
                };
            }),
        [renewedContractsMap],
    );

    return { activeContracts: activeContracts, openOffers: openOffersMock, renewedContracts: renewedContractList };
};

export default useContracts;
