import useAuth from 'hooks/useAuth';
import { MouseEvent, useState } from 'react';

const useProfileMenu = () => {
    const { user } = useAuth();
    const [anchorButton, setAnchorButton] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorButton);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorButton(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorButton(null);
    };

    return { anchorButton, email: user?.email ?? '', isOpen, handleClick, handleClose };
};

export default useProfileMenu;
