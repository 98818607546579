import { Box, styled } from '@mui/material';
import { cardPalette } from 'styles/theme/palette';

export const CardWrapper = styled(Box)<{ isActive?: boolean; isSelected?: boolean }>(
    ({ isActive = false, isSelected = false, theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        border: `1px solid ${isSelected ? theme.palette.primary.main : cardPalette.border}`,
        padding: '32px 24px',
        position: 'relative',
        background: isActive
            ? 'linear-gradient(219deg, rgba(255,255,255,1) 54%, rgba(252,252,226,1) 80%, rgba(251,252,226,1) 100%)'
            : theme.palette.background.paper,

        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
);
