import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';

export const NavItem = styled(NavLink)(({ theme }) => ({
    background: 'transparent',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    padding: theme.spacing(0.75, 1.5),
    marginRight: theme.spacing(1.5),
    borderRadius: theme.spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,

    '& .MuiListItemIcon-root': {
        fill: 'none',
        color: 'transparent',
    },

    '&.active, &:hover': {
        '& .MuiTypography-root': {
            color: theme.palette.primary.main,
        },

        color: theme.palette.primary.main,
        background: theme.palette.primary.light,
    },
}));
