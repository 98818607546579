import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { CheckIcon } from 'assets/icons';
import { CHECK_ICON_HEIGHT, CHECK_ICON_WIDTH, attributeTextSizes } from 'data/configs/attribute-item';
import { palette } from 'styles/theme/palette';

const GreenCheck = styled(CheckIcon)({
    color: palette.primary.main,
    width: CHECK_ICON_WIDTH,
    height: CHECK_ICON_HEIGHT,
});

interface AttributeItemProps {
    label: string;
    size?: 'sm' | 'lg';
}

const AttributeItem = ({ label, size = 'lg' }: AttributeItemProps) => {
    return (
        <Box>
            <Typography display={'flex'} alignItems={'center'} gap={1} variant={attributeTextSizes[size]}>
                <GreenCheck /> {label}
            </Typography>
        </Box>
    );
};

export default AttributeItem;
