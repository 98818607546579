import { Button, Stack } from '@mui/material';
import LinkButton from 'components/ui/LinkButton';
import PageControls from 'components/ui/PageControls';
import { useTranslation } from 'react-i18next';

interface OfferSummaryPageControlsProps {
    contractUuid?: string;
}

const OfferSummaryPageControls = ({ contractUuid }: OfferSummaryPageControlsProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonTranslate } = useTranslation('common');

    const pageControlsStart = (
        <LinkButton to={`/customer/contract/${contractUuid}/new-offer/configuration`} variant="text">
            {commonTranslate('BACK')}
        </LinkButton>
    );

    const pageControlsEnd = (
        <Stack gap={1} flexDirection={'row'}>
            <Button variant="outlined">{commonTranslate('SAVE_AND_CLOSE')}</Button>
            <LinkButton to={`/customer/contract/${contractUuid}/new-offer/success`} variant="contained">
                {t('CLOSE_A_DEAL')}
            </LinkButton>
        </Stack>
    );
    return <PageControls startControl={pageControlsStart} endControl={pageControlsEnd} />;
};

export default OfferSummaryPageControls;
