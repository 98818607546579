import { Divider, Stack, Typography, styled } from '@mui/material';
import { PowerSourceOption } from 'data/configs/new-offer-configuration';
import PricingSectionRow from './PricingSectionRow';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'assets/icons';
import PricingTotalPriceRow from './PricingTotalPriceRow';

interface VariablePricingViewProps {
    powerSourceInfo?: PowerSourceOption;
}

const INFO_ICON_SIZE = 18;

const PricingInfoIcon = styled(InfoIcon)({
    width: INFO_ICON_SIZE,
    height: INFO_ICON_SIZE,
});

const VariablePricingView = ({ powerSourceInfo }: VariablePricingViewProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonT } = useTranslation('common');

    return (
        <Stack gap={3}>
            <Stack
                flexDirection={{ md: 'row', sm: 'column' }}
                justifyContent={'space-between'}
                alignItems={{ md: 'center', sm: 'flex-start' }}
            >
                <PricingSectionRow
                    title={t('PRICING_SECTION_ELECTRICITY_PRODUCT')}
                    value={t('PRICING_SECTION_TYPE_SPOT_MARKET')}
                />
                <Typography display={'flex'} gap={1} alignItems={'baseline'} variant="bodyRegularEmphasized">
                    {t('PRICING_SECTION_TYPE_SPOT_MARKET_PRICE')}
                    <PricingInfoIcon />
                </Typography>
            </Stack>
            <PricingSectionRow
                title={t('PRICING_SECTION_POWER_SOURCE')}
                value={powerSourceInfo?.title}
                pricePerKW={commonT('RP_PER_KWH', { price: powerSourceInfo?.pricePerKwh })}
                pricePerYear={commonT('PRICE_PER_YEAR', { price: powerSourceInfo?.pricePerYear })}
            />
            <Divider />
            <Stack flexDirection={{ md: 'row', sm: 'column' }} justifyContent={'space-between'} alignItems={'center'}>
                <PricingTotalPriceRow />
                <Typography variant="bodyRegularEmphasized">
                    {t('PRICING_SECTION_TYPE_SPOT_MARKET_TOTAL_PRICE', { price: powerSourceInfo?.pricePerKwh })}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default VariablePricingView;
