import { Stack, Typography } from '@mui/material';
import Show from 'components/wrappers/Show';
import { ReactNode } from 'react';

interface ContractOverviewRowProps {
    label?: string;
    value?: string | ReactNode;
}

const ContractOverviewRow = ({ label, value }: ContractOverviewRowProps) => {
    return (
        <Show condition={!!value}>
            <Stack flexDirection={{ md: 'row', sm: 'column' }}>
                <Typography width={{ md: 124, sm: '100%' }} variant="bodySmallEmphasized">
                    {label}
                </Typography>
                <Typography variant="bodySmall">{value}</Typography>
            </Stack>
        </Show>
    );
};

export default ContractOverviewRow;
