import { Divider, Stack } from '@mui/material';
import { ContractDurationOption, PowerSourceOption } from 'data/configs/new-offer-configuration';
import { useTranslation } from 'react-i18next';
import PricingSectionRow from './PricingSectionRow';
import PricingTotalPriceRow from './PricingTotalPriceRow';

interface UniformPricingViewProps {
    durationInfo?: ContractDurationOption;
    powerSourceInfo?: PowerSourceOption;
}

const UniformPricingView = ({ durationInfo, powerSourceInfo }: UniformPricingViewProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonT } = useTranslation('common');
    return (
        <Stack gap={3}>
            <PricingSectionRow
                title={t('PRICING_SECTION_ELECTRICITY_PRODUCT')}
                value={durationInfo?.title}
                pricePerKW={commonT('RP_PER_KWH', { price: durationInfo?.pricePerKwh })}
                pricePerYear={commonT('PRICE_PER_YEAR', { price: durationInfo?.pricePerYear })}
            />
            <PricingSectionRow
                title={t('PRICING_SECTION_POWER_SOURCE')}
                value={powerSourceInfo?.title}
                pricePerKW={commonT('RP_PER_KWH', { price: powerSourceInfo?.pricePerKwh })}
                pricePerYear={commonT('PRICE_PER_YEAR', { price: powerSourceInfo?.pricePerYear })}
            />
            <Divider />
            <PricingTotalPriceRow
                totalPrice={Number(
                    (Number(powerSourceInfo?.pricePerKwh) + Number(durationInfo?.pricePerKwh)).toFixed(2),
                )}
            />
        </Stack>
    );
};

export default UniformPricingView;
