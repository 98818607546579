import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import ContractOverviewRow from './ContractOverviewRow';
import { useTranslation } from 'react-i18next';

interface CustomerInfoSectionProps {
    customer: {
        no: string;
        companyInvoiceEmail: string;
        companyName?: string;
        address: {
            street: string;
            zip: string;
            city: string;
        };
        contact: { fullName: string; phoneNumber: string; email: string };
    } | null;
}

const CustomerInfoSection = ({ customer }: CustomerInfoSectionProps) => {
    const { t } = useTranslation('offer-summary');

    return (
        <Stack gap={3} mt={4}>
            <ContractOverviewRow label={t('CUSTOMER_INFO_NO')} value={customer?.no} />
            <ContractOverviewRow
                label={t('CUSTOMER_INFO_BILLING_ADDRESS')}
                value={
                    <Typography>
                        <Typography variant="bodySmall">{customer?.companyName}</Typography>
                        <Typography variant="bodySmall">
                            {customer?.address.street}, {customer?.address.zip} {customer?.address.city}
                        </Typography>
                        <Typography variant="bodySmall">{customer?.companyInvoiceEmail}</Typography>
                    </Typography>
                }
            />
            <ContractOverviewRow
                label={t('CUSTOMER_INFO_RECEPTION_TYPE')}
                value={
                    <RadioGroup defaultValue={'companyInvoice'} sx={{ gap: 1 }}>
                        <FormControlLabel
                            value="companyInvoice"
                            control={<Radio />}
                            label={t('CUSTOMER_SEND_INVOICE_MAIL', { email: customer?.companyInvoiceEmail })}
                        />
                        <FormControlLabel
                            value="customerAddress"
                            control={<Radio />}
                            label={t('CUSTOMER_SEND_INVOICE_ADDRESS', { address: customer?.address.street })}
                        />
                    </RadioGroup>
                }
            />
            <ContractOverviewRow
                label={t('CUSTOMER_INFO_CONTACT')}
                value={
                    <Typography>
                        <Typography variant="bodySmall">{customer?.contact.fullName}</Typography>
                        <Typography variant="bodySmall">{customer?.contact.phoneNumber}</Typography>
                        <Typography variant="bodySmall">{customer?.contact.email}</Typography>
                    </Typography>
                }
            />
        </Stack>
    );
};

export default CustomerInfoSection;
