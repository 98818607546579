import { Box, Divider, Stack, Typography } from '@mui/material';
import IgnorePadding from 'components/wrappers/IgnorePadding';
import Show from 'components/wrappers/Show';
import { PowerSourceOption, PowerSourceType } from 'data/configs/new-offer-configuration';
import { Trans, useTranslation } from 'react-i18next';
import CardBanner from 'styles/styled-components/CardBanner';
import { CardWrapper } from 'styles/styled-components/CardWrapper';
import CardCheckBox from './CardCheckBox';
import SectionBasicInfo from './SectionBasicInfo';

const RP_PER_KWH = 'Rp / kWh';
const PLUS_SIGN = '+';

interface PowerSourceCardProps extends PowerSourceOption {
    onClick: (selectedPowerSource: PowerSourceType) => void;
    isSelected?: boolean;
}

const PowerSourceCard = ({
    type,
    attribute,
    image,
    origin,
    pricePerKwh,
    pricePerYear,
    title,
    isActive,
    isSelected,
    onClick,
}: PowerSourceCardProps) => {
    const { t } = useTranslation('configuration');
    const { t: commonT } = useTranslation('common');

    return (
        <CardWrapper
            isSelected={isSelected}
            onClick={() => onClick(type)}
            flexGrow={1}
            isActive={isActive}
            width={'236px'}
        >
            <Show condition={!!isActive}>
                <CardBanner>{commonT('CURRENT_CHOICE')}</CardBanner>
            </Show>

            <Stack flexGrow={1} gap={3}>
                <Stack flexGrow={1} gap={2}>
                    <Stack>
                        <SectionBasicInfo size="xs" title={title} name={t('POWER_SOURCE_CARD_PROOF_OF_ORIGIN')} />
                    </Stack>
                    <Stack flexGrow={1}>
                        <img style={{ maxHeight: '91px', maxWidth: '115px', margin: 'auto' }} src={image} alt={title} />
                    </Stack>
                </Stack>

                <Stack gap={1}>
                    <Trans>
                        <Typography variant="bodyRegularEmphasized">{origin}</Typography>
                    </Trans>
                    <Typography variant="caption">{attribute}</Typography>
                </Stack>
            </Stack>

            <IgnorePadding>
                <Divider sx={{ mt: 7, mb: 2 }} />
            </IgnorePadding>
            <Stack gap={2} alignItems={'center'}>
                <Box>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="bodySmall">
                        <Typography variant="leadEmphasized" fontWeight={700}>
                            {PLUS_SIGN} {pricePerKwh}
                        </Typography>
                        {RP_PER_KWH}
                    </Typography>
                    <Typography variant="caption">
                        {<Trans t={t} i18nKey={'CONTRACT_DURATION_PER_YEAR_PRICE'} values={{ price: pricePerYear }} />}
                    </Typography>
                </Box>
                <CardCheckBox checked={isSelected} />
            </Stack>
        </CardWrapper>
    );
};

export default PowerSourceCard;
