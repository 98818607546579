import { Button, Stack } from '@mui/material';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { useTranslation } from 'react-i18next';

interface PowerConsumptionIntroSectionProps {
    powerSource?: string;
    location?: string;
    toggleDialog?: () => void;
}

const PowerConsumptionIntroSection = ({ location, powerSource, toggleDialog }: PowerConsumptionIntroSectionProps) => {
    const { t } = useTranslation('power-consumption');
    const { t: commonT } = useTranslation('common');

    return (
        <Stack gap={3} mt={8}>
            <SectionBasicInfo name={`${powerSource}, ${location}`} title={t('TITLE')} description={t('DESCRIPTION')} />

            <Stack gap={2} flexDirection={'row'}>
                <Button onClick={toggleDialog} color="inherit">
                    {commonT('YES')}
                </Button>
                <Button color="inherit">{commonT('NO')}</Button>
            </Stack>
        </Stack>
    );
};

export default PowerConsumptionIntroSection;
