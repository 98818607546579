import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import OfferBasicInfoRow from './OfferBasicInfoRow';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'assets/icons';

interface VariableBasicInfoProps {
    riskPremium?: number | string;
    fullCoverage?: number | string;
    contractStartDate?: string;
    expectedConsumption?: number | string;
}

const INFO_ICON_SIZE = '16.67px';

const OfferInfoIcon = styled(InfoIcon)({
    width: INFO_ICON_SIZE,
    height: INFO_ICON_SIZE,
});

const VariableBasicInfo = ({ contractStartDate, riskPremium, fullCoverage }: VariableBasicInfoProps) => {
    const { t } = useTranslation('offer-summary');
    return (
        <Stack gap={1.5}>
            <OfferBasicInfoRow
                label={t('OFFER_START')}
                value={
                    <Typography display={'flex'} alignItems={'center'} gap={1}>
                        {t('FROM_DATE', { date: contractStartDate })} <OfferInfoIcon />
                    </Typography>
                }
            />
            <OfferBasicInfoRow
                label={t('EXPECTED_SUPPLY_QUANTITY')}
                value={
                    <Typography display={'flex'} alignItems={'center'} gap={1}>
                        {t('EXPECTED_SUPPLY_QUANTITY_VALUE', { value: "124'654" })} <OfferInfoIcon />
                    </Typography>
                }
            />
            <OfferBasicInfoRow
                label={t('COMPOSITION_OF_COST')}
                value={
                    <Typography display={'flex'} alignItems={'center'} gap={1}>
                        {t('COMPOSITION_OF_COST_VALUE', { riskPremium, fullCoverage })}
                    </Typography>
                }
            />
        </Stack>
    );
};

export default VariableBasicInfo;
