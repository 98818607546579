import publicRoutes from 'data/routes/public-routes';
import { Navigate, Route, Routes } from 'react-router-dom';

const PublicRouter = () => {
    return (
        <Routes>
            {publicRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={route.component} />
            ))}
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
};

export default PublicRouter;
