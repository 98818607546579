import i18n from 'i18n';
import fixImage from 'assets/images/grafik-ckw-garant.png';
import varImage from 'assets/images/grafik-ckw-spot.png';
import { ProductSelected } from 'store/newOfferFlow';

export const getProductList = (price: string | number) => [
    {
        type: 'constant' as ProductSelected,
        typeTitle: i18n.t('product-selection:FIXED_CARD_TYPE'),
        costTitle: i18n.t('product-selection:FIXED_CARD_COST_TITLE', { price }),
        description: i18n.t('product-selection:FIXED_CARD_DESCRIPTION'),
        attributes: [
            i18n.t('product-selection:FIXED_CARD_ATTR_1'),
            i18n.t('product-selection:FIXED_CARD_ATTR_2'),
            i18n.t('product-selection:FIXED_CARD_ATTR_3'),
        ],
        image: fixImage,
    },
    {
        type: 'variable' as ProductSelected,
        typeTitle: i18n.t('product-selection:VARIABLE_CARD_TYPE'),
        costTitle: i18n.t('product-selection:VARIABLE_CARD_COST_TITLE'),
        description: i18n.t('product-selection:VARIABLE_CARD_DESCRIPTION'),
        attributes: [
            i18n.t('product-selection:VARIABLE_CARD_ATTR_1'),
            i18n.t('product-selection:VARIABLE_CARD_ATTR_2'),
            i18n.t('product-selection:VARIABLE_CARD_ATTR_3'),
        ],
        image: varImage,
    },
];
