import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { Route } from 'data/routes/navigation-routes';
import useToggle from 'hooks/useToggle';
import MobileNavItems from './MobileNavItems';
import MobileProfileItems from './MobileProfileItems';

interface MobileMenuProps {
    navRoutes: Route[];
    profileRoutes: Route[];
}

const MobileMenu = ({ navRoutes, profileRoutes }: MobileMenuProps) => {
    const { value: isOpen, toggle: toggleDrawer } = useToggle();

    return (
        <Box sx={{ display: { xs: 'flex', md: 'none' }, flex: 1, justifyContent: 'flex-end' }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Drawer id="mobileNavDrawer" anchor={'top'} open={isOpen} onClose={toggleDrawer}>
                <MobileNavItems navRoutes={navRoutes} toggleDrawer={toggleDrawer} />
                <Divider sx={{ margin: 2 }} />
                <MobileProfileItems profileRoutes={profileRoutes} toggleDrawer={toggleDrawer} />
                <Divider sx={{ margin: 2 }} />
            </Drawer>
        </Box>
    );
};

export default MobileMenu;
