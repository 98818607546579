import { styled } from '@mui/material';

interface FullwidthWrapperProps {
    children: React.ReactNode;
}

const SimpleBox = styled('div')({
    width: '100vw',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
});

const FullwidthWrapper = ({ children }: FullwidthWrapperProps) => {
    return <SimpleBox>{children}</SimpleBox>;
};

export default FullwidthWrapper;
