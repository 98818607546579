import Configuration from 'pages/NewOffer/Steps/Configuration';
import OfferSummary from 'pages/NewOffer/Steps/OfferSummary';
import PowerConsumption from 'pages/NewOffer/Steps/PowerConsumption';
import ProductSelection from 'pages/NewOffer/Steps/ProductSelection';

export default [
    {
        path: '/power-consumption',
        component: <PowerConsumption />,
        exact: true,
    },
    {
        path: '/product-selection',
        component: <ProductSelection />,
        exact: true,
    },
    {
        path: '/configuration',
        component: <Configuration />,
        exact: true,
    },
    {
        path: '/offer-summary',
        component: <OfferSummary />,
        exact: true,
    },
];
