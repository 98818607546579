import { Typography } from '@mui/material';

interface StepLabelProps {
    index: number;
    isLastStep: boolean;
    children: React.ReactNode;
}

const StepLabel = ({ index, isLastStep, children }: StepLabelProps) => (
    <Typography
        variant="captionSmall"
        sx={{
            position: 'absolute',
            top: 'calc(100% + 8px)',
            width: 'max-content',
            display: { xs: 'none', sm: 'block' },
            left: { sm: index === 0 ? '0' : 'unset', md: 'unset' },
            right: { sm: isLastStep ? '0' : 'unset', md: 'unset' },
        }}
    >
        {children}
    </Typography>
);

export default StepLabel;
