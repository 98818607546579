// need some way to set 4 timers for each stage and then change states to isFinished: true every 5 seconds
export const mockStateChecker = (onStateResolved: (step: string) => void) => {
    const stages = ['step1', 'step2', 'step3', 'step4']; // replace with your actual stages

    stages.forEach((stage, index) => {
        setTimeout(
            () => {
                onStateResolved(stage);
            },
            (index + 1) * 5000,
        );
    });
};
