import { Button, Stack, Typography } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons';
import InfoBanner from 'components/ui/InfoBanner';
import { Trans, useTranslation } from 'react-i18next';

const InfoBannerContent = () => {
    const { t } = useTranslation('offer-summary');
    const { t: tCommon } = useTranslation('common');
    return (
        <InfoBanner>
            <Stack justifyContent={'flex-start'}>
                <Trans>
                    <Typography variant="bodyRegularEmphasized">{t('INFO_BANNER_TITLE')}</Typography>
                </Trans>
                <Button sx={{ width: 'fit-content', padding: 0 }} variant="text">
                    {tCommon('LEARN_MORE')} <ArrowRightIcon />
                </Button>
            </Stack>
        </InfoBanner>
    );
};

export default InfoBannerContent;
