import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import Show from 'components/wrappers/Show';
import { Trans, useTranslation } from 'react-i18next';
import CardBanner from 'styles/styled-components/CardBanner';
import { CardWrapper } from 'styles/styled-components/CardWrapper';
import CardCheckBox from './CardCheckBox';
import IgnorePadding from 'components/wrappers/IgnorePadding';
import { InfoIcon } from 'assets/icons';
import { ContractDurationType } from 'data/configs/new-offer-configuration';

const RP_PER_KWH = 'Rp / kWh';
const AVERAGE_SIGN = '⌀';
const INFO_ICON_SIZE = '20px';

const ConfigInfoIcon = styled(InfoIcon)({
    width: INFO_ICON_SIZE,
    height: INFO_ICON_SIZE,
});

interface ContractDurationCardProps {
    type: ContractDurationType;
    title: string;
    image: string;
    pricePerKwh: number;
    pricePerYear: number;
    isActive?: boolean;
    showInfoIcon?: boolean;
    isSelected?: boolean;
    onClick: (selectedDuration: ContractDurationType) => void;
}

const ContractDurationCard = ({
    type,
    title,
    image,
    pricePerKwh,
    pricePerYear,
    isActive,
    showInfoIcon,
    onClick,
    isSelected,
}: ContractDurationCardProps) => {
    const { t } = useTranslation('configuration');
    const { t: commonT } = useTranslation('common');

    return (
        <CardWrapper isSelected={isSelected} onClick={() => onClick(type)} isActive={isActive} maxWidth={'309.33px'}>
            <Stack gap={9} flexGrow={1}>
                <Show condition={!!isActive}>
                    <CardBanner>{commonT('CURRENT_CHOICE')}</CardBanner>
                </Show>

                <Typography display={'inline'} flexGrow={1} variant="leadEmphasized">
                    {title}{' '}
                    <Show condition={!!showInfoIcon}>
                        <ConfigInfoIcon data-testid="info-icon" />
                    </Show>
                </Typography>

                <IgnorePadding>
                    <img style={{ width: '100%' }} src={image} alt={title} />
                </IgnorePadding>
            </Stack>

            <IgnorePadding>
                <Divider sx={{ mt: 9, mb: 2 }} />
            </IgnorePadding>

            <Stack gap={2} alignItems={'center'}>
                <Box>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="bodySmall">
                        <Typography variant="leadEmphasized" fontWeight={700}>
                            <Show condition={type === 'three-year-annual'}>{AVERAGE_SIGN}</Show> {pricePerKwh}
                        </Typography>
                        {RP_PER_KWH}
                    </Typography>
                    <Typography variant="caption" data-testid="price-per-year">
                        {<Trans t={t} i18nKey={'CONTRACT_DURATION_PER_YEAR_PRICE'} values={{ price: pricePerYear }} />}
                    </Typography>
                </Box>
                <CardCheckBox checked={isSelected} />
            </Stack>
        </CardWrapper>
    );
};

export default ContractDurationCard;
