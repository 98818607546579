import { Stack, Typography } from '@mui/material';
import { palette } from 'styles/theme/palette';

export interface ContractInfoRowProps {
    label: string;
    value: string;
    accentValue?: boolean;
}

const ContractInfoRow = ({ label, value, accentValue }: ContractInfoRowProps) => (
    <Stack gap={0.5}>
        <Typography variant="bodyRegularEmphasized">{label}</Typography>
        <Typography
            sx={{ overflowWrap: 'break-word' }}
            variant={accentValue ? 'bodyRegularEmphasized' : 'bodyRegular'}
            color={accentValue ? palette.primary.main : palette.text?.primary}
        >
            {value}
        </Typography>
    </Stack>
);

export default ContractInfoRow;
