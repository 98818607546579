import { Button, Stack } from '@mui/material';
import { ShareIcon } from 'assets/icons';
import TwoByTwo from 'components/layouts/TwoByTwoLayout';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import TimerBanner from 'components/ui/TimerBanner';
import { useTranslation } from 'react-i18next';
import ContractOverviewRow from './ContractOverviewRow';

interface ContractOverviewSectionProps {
    contractName?: string;
    contractLocation?: string;
    contractMeter?: string;
    contractOfferNO?: string;
    contractRevisionId?: string;
}

const ContractOverviewSection = ({
    contractName,
    contractLocation,
    contractMeter,
    contractOfferNO,
    contractRevisionId,
}: ContractOverviewSectionProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonT } = useTranslation('common');
    const shareOffer = (
        <Button sx={{ display: 'flex', gap: 2, width: 'fit-content' }} variant="text">
            <ShareIcon sx={{ fill: 'transparent' }} />
            {commonT('SHARE_OFFER')}
        </Button>
    );

    const overview = (
        <Stack gap={1.5}>
            <ContractOverviewRow label={t('OVERVIEW_CONTRACT')} value={contractName} />
            <ContractOverviewRow label={t('OVERVIEW_LOCATION')} value={contractLocation} />
            <ContractOverviewRow label={t('OVERVIEW_METER')} value={contractMeter} />
            <ContractOverviewRow label={t('OVERVIEW_OFFER_NO')} value={contractOfferNO} />
            <ContractOverviewRow label={t('OVERVIEW_REVISION_ID')} value={contractRevisionId} />
        </Stack>
    );

    const bottomRightContent = <TimerBanner sx={{ maxHeight: '109px' }} timeLeft={59} validTill="15:14" />;

    return (
        <TwoByTwo
            topLeftContent={<SectionBasicInfo title={t('TITLE')} />}
            topRightContent={shareOffer}
            bottomLeftContent={overview}
            bottomRightContent={bottomRightContent}
        />
    );
};

export default ContractOverviewSection;
