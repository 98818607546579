import { Stack, Typography } from '@mui/material';

interface PricingSectionRowProps {
    title?: string;
    value?: string;
    pricePerKW?: string;
    pricePerYear?: string;
}

const PricingSectionRow = ({ title, value, pricePerKW, pricePerYear }: PricingSectionRowProps) => (
    <Stack flexDirection={{ md: 'row', sm: 'column' }} justifyContent={'space-between'}>
        <Stack>
            <Typography variant="caption">{title}</Typography>
            <Typography variant="bodyRegularEmphasized">{value}</Typography>
        </Stack>
        <Stack>
            <Typography variant="bodyRegularEmphasized">{pricePerKW}</Typography>
            <Typography variant="caption">{pricePerYear}</Typography>
        </Stack>
    </Stack>
);

export default PricingSectionRow;
