import { CssBaseline, ThemeProvider } from '@mui/material';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from 'styles/theme/theme';

interface IGlobalProviders {
    children: React.ReactNode;
}

const GlobalProviders = ({ children }: IGlobalProviders) => {
    return (
        <Suspense fallback="Loading...">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>{children}</BrowserRouter>
            </ThemeProvider>
        </Suspense>
    );
};

export default GlobalProviders;
