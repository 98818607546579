import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const expiresIn = (endDateString?: string) => {
    if (!endDateString) {
        return;
    }

    const endDate = dayjs(endDateString, 'DD.MM.YYYY').startOf('day');
    const currentDate = dayjs().startOf('day');

    return endDate.diff(currentDate, 'day');
};
