import { AppBar, IconButton, Toolbar } from '@mui/material';
import { LogoIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import { navRoutes, profileRoutes } from 'data/routes/navigation-routes';
import useHeader from 'view-controllers/ui/useHeader.controller';
import Show from 'components/wrappers/Show';

const Header = () => {
    const { isNavigationHidden } = useHeader();

    return (
        <AppBar color="default" position="relative" sx={{ padding: 0, zIndex: 2 }}>
            <Toolbar>
                <Link to={isNavigationHidden ? '#' : '/'}>
                    <IconButton disableRipple sx={{ mr: 2 }}>
                        <LogoIcon data-testid="logo-icon" sx={{ width: '96px', height: '50px' }} />
                    </IconButton>
                </Link>
                <Show condition={!isNavigationHidden}>
                    <DesktopMenu navRoutes={navRoutes} profileRoutes={profileRoutes} />
                    <MobileMenu navRoutes={navRoutes} profileRoutes={profileRoutes} />
                </Show>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
