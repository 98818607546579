import { stepUrls } from 'data/configs/existing-customer-new-offer';
import { getContractById } from 'data/mocks/contracts-mock';
import useToggleNavigation from 'hooks/useToggleNavigation';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useNewOfferFlowStore from 'store/newOfferFlow';

const useNewOffer = () => {
    const { pathname } = useLocation();
    const { contractId } = useParams();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const { setContract } = useNewOfferFlowStore();
    useToggleNavigation();

    const handleStepChange = (step: number) => {
        setCurrentStep(step);
    };

    const setStepFromUrl = useCallback(() => {
        const step = pathname.split('/').pop();
        if (step) {
            const stepIndex = stepUrls[step];
            if (stepIndex !== undefined) {
                handleStepChange(stepIndex);
            }
        }
    }, [pathname]);

    const fetchContract = useCallback(() => {
        if (!contractId) return;

        const contract = getContractById(contractId);
        if (contract) {
            setContract(contract);
        }
    }, [contractId, setContract]);

    useEffect(() => {
        fetchContract();
    }, [fetchContract]);

    useEffect(() => {
        setStepFromUrl();
    }, [setStepFromUrl]);

    return { currentStep };
};

export default useNewOffer;
