import { Stack, Box, Container, Typography, Button } from '@mui/material';
import notFoundAnimation from 'data/configs/not-found-animation';
import { useLottie } from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    const { t } = useTranslation('not-found');
    const { View } = useLottie(notFoundAnimation);
    return (
        <Container>
            <Stack
                sx={{
                    flexDirection: { xs: 'column-reverse', md: 'row' },
                    marginTop: { xs: 6, sm: 11 },
                    marginBottom: 3,
                }}
            >
                <Box>
                    <Typography variant="heading4">{t('ERROR_404')}</Typography>
                    <Typography variant="titleSmall" sx={{ margin: '0 0 16px 0' }}>
                        {t('DESCRIPTION_404_DEAD_END')}
                    </Typography>
                    <Typography variant="lead" sx={{ margin: '0 0 32px 0' }}>
                        {t('DESCRIPTION_404_PAGE_LOST')}
                    </Typography>
                    <NavLink to={'/'}>
                        <Button variant="outlined">{t('BACK_TO_HOME_PAGE')}</Button>
                    </NavLink>
                </Box>
                <Box>{View}</Box>
            </Stack>
        </Container>
    );
};

export default NotFound;
