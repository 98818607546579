import { Stack, Typography } from '@mui/material';
import Show from 'components/wrappers/Show';
import { useTranslation } from 'react-i18next';

interface PricingTotalPriceRowProps {
    totalPrice?: number;
}

const PricingTotalPriceRow = ({ totalPrice }: PricingTotalPriceRowProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonT } = useTranslation('common');
    return (
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack gap={0.5}>
                <Typography variant="leadEmphasized">{t('PRICING_SECTION_TOTAL_PRICE')}</Typography>
                <Typography variant="caption">{t('PRICING_SECTION_TOTAL_PRICE_NOTE')}</Typography>
            </Stack>
            <Show condition={!!totalPrice}>
                <Typography variant="leadEmphasized">{commonT('RP_PER_KWH', { price: totalPrice })}</Typography>
            </Show>
        </Stack>
    );
};

export default PricingTotalPriceRow;
