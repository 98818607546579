import { CustomTypographyVariant } from 'types/mui-theme';

export const CHECK_ICON_WIDTH = '20.13px';
export const CHECK_ICON_HEIGHT = '14px';

type Size = 'sm' | 'lg';

export const attributeTextSizes: Record<Size, CustomTypographyVariant> = {
    sm: 'bodySmall',
    lg: 'bodyRegular',
};
