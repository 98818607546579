import { Stack } from '@mui/material';
import ProductSelectionList from 'components/pages/NewOffer/ProductSelection/ProductSelectionList';
import ProductSelectionPageControls from 'components/pages/NewOffer/ProductSelection/ProductSelectionPageControls';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { useTranslation } from 'react-i18next';
import useProductSelection from 'view-controllers/pages/NewOffer/useProductSelection.controller';

const ProductSelection = () => {
    const { contract, productList, selectedProduct, handleProductSelect } = useProductSelection();
    const { t } = useTranslation('product-selection');

    return (
        <Stack flexGrow={1} justifyContent={'space-between'}>
            <Stack gap={3} mt={8}>
                <SectionBasicInfo
                    name={`${contract?.powerSource}, ${contract?.location}`}
                    title={t('TITLE')}
                    description={t('DESCRIPTION')}
                />
                <ProductSelectionList
                    handleProductSelect={handleProductSelect}
                    productList={productList}
                    selectedProduct={selectedProduct}
                />
            </Stack>
            <ProductSelectionPageControls contractUuid={contract?.uuid} isNextDisabled={!selectedProduct} />
        </Stack>
    );
};

export default ProductSelection;
