export interface Route {
    path: string;
    label: string;
    icon?: string;
}

export const navRoutes = [
    {
        path: '/customer/overview',
        label: 'NAVIGATION_OVERVIEW',
    },
    {
        path: '/customer/power-consumption',
        label: 'NAVIGATION_POWER_CONSUMPTION',
    },
    {
        path: '/customer/bills',
        label: 'NAVIGATION_BILLS',
    },
    {
        path: '/customer/electricity-contracts',
        label: 'NAVIGATION_ELECTRICITY_CONTRACTS',
    },
];

export const profileRoutes = [
    {
        path: '/customer/profile',
        label: 'PROFILE_NAVIGATION_PROFILE',
    },
    {
        path: '/customer/settings',
        label: 'PROFILE_NAVIGATION_SETTINGS',
    },
];
