import { Stack } from '@mui/material';
import ProductSelectionCard from 'components/ui/ProductSelectionCard/ProductSelectionCard';
import React from 'react';
import { ProductSelected } from 'store/newOfferFlow';

interface ProductSelectionListProps {
    productList: {
        type: ProductSelected;
        typeTitle: string;
        costTitle: string;
        description: string;
        attributes: string[];
        image: string;
    }[];
    selectedProduct: ProductSelected | null;
    handleProductSelect: (productType: ProductSelected) => void;
}

const ProductSelectionList = ({ productList, selectedProduct, handleProductSelect }: ProductSelectionListProps) => {
    return (
        <Stack flexWrap={'wrap'} gap={5} flexDirection={'row'} mb={(theme) => theme.typography.pxToRem(54)}>
            {productList.map((product, index) => (
                <ProductSelectionCard
                    key={index}
                    type={product.type}
                    typeTitle={product.typeTitle}
                    costTitle={product.costTitle}
                    description={product.description}
                    attributes={product.attributes}
                    imageSrc={product.image}
                    isSelected={selectedProduct === product.type}
                    onProductSelect={handleProductSelect}
                />
            ))}
        </Stack>
    );
};

export default ProductSelectionList;
