import { PowerSourceType, PowerSourceValue } from 'data/configs/new-offer-configuration';

export const powerSourceMock: Record<PowerSourceType, PowerSourceValue> = {
    nuclear: {
        pricePerKwh: '0.20',
        pricePerYear: 88,
    },
    'water-eu': {
        pricePerKwh: 5.13,
        pricePerYear: "2'237",
    },
    'water-ch': {
        pricePerKwh: 7.57,
        pricePerYear: "3'546",
    },
    photovoltaik: {
        pricePerKwh: 21.03,
        pricePerYear: "9'164",
    },
};
