import { Box, Stack, Typography, styled } from '@mui/material';
import { CheckIcon } from 'assets/icons';
import DCPLoaderSpinner from 'components/ui/DCPLoaderSpinner';
import { palette } from 'styles/theme/palette';

interface Check {
    label: string;
    isFinished: boolean;
}

interface LoadingChecksProps {
    list: Check[];
}

const CheckWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 20,
    height: 20,
    flexShrink: 1,
    position: 'relative',
    backgroundColor: palette.primary.main,
}));

const WhiteCheckIcon = styled(CheckIcon)({
    color: palette.common.white,
    width: '13.42px',
    height: '9.34px',
});

const LoadingChecks = ({ list }: LoadingChecksProps) => {
    return (
        <Stack gap={2.5}>
            {list.map((check, index) => (
                <Stack key={index} direction="row" alignItems="center" spacing={1}>
                    <Box sx={{ width: '24px', height: '24px' }}>
                        {check.isFinished ? (
                            <CheckWrapper>
                                <WhiteCheckIcon />
                            </CheckWrapper>
                        ) : (
                            <DCPLoaderSpinner />
                        )}
                    </Box>
                    <Typography variant="bodySmall">{check.label}</Typography>
                </Stack>
            ))}
        </Stack>
    );
};

export default LoadingChecks;
