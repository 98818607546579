import NotFound from 'pages/NotFound';
import { Route, Routes } from 'react-router-dom';
import ProtectedRouter from './ProtectedRouter';
import PublicRouter from './PublicRouter';

const Main = () => {
    return (
        <Routes>
            <Route path="/customer/*" element={<ProtectedRouter />} />
            <Route path="/*" element={<PublicRouter />} />
            <Route path="/404" element={<NotFound />} />
        </Routes>
    );
};

export default Main;
