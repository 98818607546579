import { CustomTypographyVariant } from 'types/mui-theme';

type Size = 'xs' | 'sm' | 'lg';

const sectionNameSize: Record<Size, CustomTypographyVariant> = {
    xs: 'caption',
    sm: 'bodySmallEmphasized',
    lg: 'bodyRegular',
};

const sectionTitleSize: Record<Size, CustomTypographyVariant> = {
    xs: 'heading4',
    sm: 'heading4',
    lg: 'heading2',
};

const sectionDescriptionSize: Record<Size, CustomTypographyVariant> = {
    xs: 'caption',
    sm: 'bodySmall',
    lg: 'bodyRegular',
};

export { sectionNameSize, sectionTitleSize, sectionDescriptionSize };
