import { Divider, Stack, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface PageControlsProps {
    startControl?: ReactNode;
    endControl?: ReactNode;
    sx?: SxProps<Theme>;
}

const PageControls = ({ sx, startControl, endControl }: PageControlsProps) => {
    return (
        <Stack sx={sx} gap={2.5}>
            <Divider />
            <Stack flexWrap={'wrap'} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                {startControl}
                {endControl}
            </Stack>
        </Stack>
    );
};

export default PageControls;
