import { getProductList } from 'data/configs/product-selection';
import useNewOfferFlowStore, { ProductSelected } from 'store/newOfferFlow';

const useProductSelection = () => {
    const { contract, calculatedOffer, selectedProduct, setSelectedProduct } = useNewOfferFlowStore();

    const productList = getProductList(calculatedOffer?.price || 0);

    const handleProductSelect = (product: ProductSelected) => {
        setSelectedProduct(product);
    };

    return { selectedProduct, contract, productList, handleProductSelect };
};

export default useProductSelection;
