import { Stack, Typography } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons';
import LinkButton from 'components/ui/LinkButton';
import Show from 'components/wrappers/Show';
import { Contract } from 'data/mocks/contracts-mock';
import { useTranslation } from 'react-i18next';
import { CardWrapper } from 'styles/styled-components/CardWrapper';
import ContractCardBanner from './ContractCardBanner';
import ContractCardBottomControl from './ContractCardBottomControl';
import ContractCardInfoSection from './ContractCardInfoSection';

interface ContractCardProps {
    contract: Contract;
    isNotStarted?: boolean;
}

const ContractCard = ({ contract, isNotStarted }: ContractCardProps) => {
    const { t } = useTranslation('contract-card');

    return (
        <CardWrapper sx={{ '&:hover': { cursor: 'default' } }} width={467} justifyContent={'space-between'}>
            <Stack gap={2}>
                <ContractCardBanner
                    isNotStarted={isNotStarted}
                    hasBeenRenewed={contract.hasBeenRenewed}
                    supplyEndDate={contract.supplyEndDate}
                    supplyStartDate={contract.supplyStartDate}
                />
                <Typography
                    display={'flex'}
                    alignItems={'center'}
                    variant="leadEmphasized"
                    color={(theme) => theme.palette.primary.main}
                >
                    {contract.powerSource}, {contract.location} <ArrowRightIcon />
                </Typography>
                <ContractCardInfoSection
                    streetAddress={contract.streetAddress}
                    postalCode={contract.postalCode}
                    city={contract.city}
                    meterId={contract.meterId}
                    contractNo={contract.contractNo}
                    product={contract.product}
                    proofOfOrigin={contract.proofOfOrigin}
                    supplyStartDate={contract.supplyStartDate}
                    supplyEndDate={contract.supplyEndDate}
                />
                <Show condition={!contract.hasBeenRenewed && !isNotStarted}>
                    <LinkButton to={`/customer/renew-contract/${contract.uuid}`} sx={{ mt: 2 }} fullWidth>
                        {t('CALCULATE_NEW_OFFER')}
                    </LinkButton>
                </Show>
            </Stack>
            <ContractCardBottomControl contractUuid={contract.uuid} hasBeenRenewed={contract.hasBeenRenewed} />
        </CardWrapper>
    );
};

export default ContractCard;
