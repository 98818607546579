import { Button, Stack, styled } from '@mui/material';
import Show from 'components/wrappers/Show';
import { ContractDurationOption, ContractDurationType, PowerSourceOption } from 'data/configs/new-offer-configuration';
import { cardPalette } from 'styles/theme/palette';
import UniformPricingView from './UniformPricingView';
import AnnualPricingView from './AnnualPricingView';
import VariablePricingView from './VariablePricingView';
import { AdditionIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';

interface OfferPricingSectionProps {
    viewType: 'variable' | ContractDurationType | null;
    selectedDurationInfo?: ContractDurationOption;
    selectedPowerSourceInfo?: PowerSourceOption;
}

const ADDITIONA_ICON_SIZE = 15;

const PricingAdditionIcon = styled(AdditionIcon)(({ theme }) => ({
    width: ADDITIONA_ICON_SIZE,
    height: ADDITIONA_ICON_SIZE,
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
}));

const OfferPricingWrapper = styled(Stack)({
    marginTop: 16,
    padding: '32px',
    backgroundColor: cardPalette.stackBgColor,
    borderRadius: 1,
});

const OfferPricingSection = ({ viewType, selectedDurationInfo, selectedPowerSourceInfo }: OfferPricingSectionProps) => {
    const { t } = useTranslation('offer-summary');
    return (
        <Stack gap={4}>
            <OfferPricingWrapper>
                <Show condition={viewType === 'one-year' || viewType === 'three-year-uniform'}>
                    <UniformPricingView durationInfo={selectedDurationInfo} powerSourceInfo={selectedPowerSourceInfo} />
                </Show>
                <Show condition={viewType === 'three-year-annual'}>
                    <AnnualPricingView durationInfo={selectedDurationInfo} powerSourceInfo={selectedPowerSourceInfo} />
                </Show>
                <Show condition={viewType === 'variable'}>
                    <VariablePricingView powerSourceInfo={selectedPowerSourceInfo} />
                </Show>
            </OfferPricingWrapper>
            <Button sx={{ display: 'flex', gap: 1, width: 'fit-content', mb: '32px' }} variant="text">
                <PricingAdditionIcon />
                {t('PRICING_SECTION_ADD_COUPON')}
            </Button>
        </Stack>
    );
};

export default OfferPricingSection;
