import { Stack, Typography, styled } from '@mui/material';
import { CardCheckedCheckboxIcon } from 'assets/icons';
import LinkButton from 'components/ui/LinkButton';
import useToggleNavigation from 'hooks/useToggleNavigation';
import { useTranslation } from 'react-i18next';
import Container from 'styles/styled-components/Container';

const ICON_SIZE = 96;

const SuccessCheckIcon = styled(CardCheckedCheckboxIcon)(({ theme }) => ({
    width: ICON_SIZE,
    height: ICON_SIZE,
    [theme.breakpoints.down('sm')]: {
        width: ICON_SIZE / 1.5,
        height: ICON_SIZE / 1.5,
    },
}));

const NewOfferSuccess = () => {
    const { t } = useTranslation('new-offer-success');
    useToggleNavigation();

    return (
        <Container sx={{ justifyContent: 'center', my: 5 }} size="md">
            <Stack alignItems={'center'} gap={3}>
                <Typography variant="heading3">{t('TITLE')}</Typography>
                <SuccessCheckIcon />
                <Typography variant="bodyRegular">{t('DESCRIPTION')}</Typography>
                <LinkButton to="/customer/electricity-contracts" sx={{ width: 'fit-content' }}>
                    {t('BUTTON')}
                </LinkButton>
            </Stack>
        </Container>
    );
};

export default NewOfferSuccess;
