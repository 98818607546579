import { List, ListItem as MuiListItem, Typography, styled } from '@mui/material';
import { Route } from 'data/routes/navigation-routes';
import { useTranslation } from 'react-i18next';
import { NavItem } from 'styles/styled-components/NavItem';

const ListItem = styled(MuiListItem)({
    padding: 0,
    width: 'auto',
});

interface DesktopNavItemsProps {
    navRoutes: Route[];
}

const DesktopNavItems = ({ navRoutes }: DesktopNavItemsProps) => {
    const { t } = useTranslation('header');

    return (
        <List sx={{ display: 'flex' }}>
            {navRoutes.map(({ path, label }) => (
                <ListItem key={label}>
                    <NavItem to={path}>
                        <Typography variant="bodyRegularEmphasized" color="inherit">
                            {t(label)}
                        </Typography>
                    </NavItem>
                </ListItem>
            ))}
        </List>
    );
};

export default DesktopNavItems;
