import { SvgIcon, SvgIconProps } from '@mui/material'

import { ReactComponent as ArrowDown } from './source/arrowDown.svg'
import { ReactComponent as ArrowRight } from './source/arrowRight.svg'
import { ReactComponent as Calendar } from './source/calendar.svg'
import { ReactComponent as Compare } from './source/compareIcon.svg'
import { ReactComponent as ContractConsumption } from './source/contractConsumption.svg'
import { ReactComponent as Download } from './source/downloadIcon.svg'
import { ReactComponent as ErrorExclamation } from './source/errorExclamation.svg'
import { ReactComponent as Home } from './source/home.svg'
import { ReactComponent as Invoice } from './source/invoice.svg'
import { ReactComponent as LogOut } from './source/logOut.svg'
import { ReactComponent as Logo } from './source/logoCKW.svg'
import { ReactComponent as PowerConsumption } from './source/powerConsuption.svg'
import { ReactComponent as Profile } from './source/profile.svg'
import { ReactComponent as Storybook } from './source/storybook.svg'
import { ReactComponent as Plug } from './source/Plug.svg'
import { ReactComponent as Check } from './source/check.svg'
import { ReactComponent as Loader } from './source/loader.svg'
import { ReactComponent as CardEmptyCheckbox } from './source/card-empty-checkbox.svg'
import { ReactComponent as CardCheckedCheckbox } from './source/card-checked-checkbox.svg'
import { ReactComponent as Share } from './source/share.svg'
import { ReactComponent as Info } from './source/info.svg'
import { ReactComponent as Multiplication } from './source/multiplication.svg'
import { ReactComponent as DiscountPercentage } from './source/discount-percentage.svg'
import { ReactComponent as Clock } from './source/clock.svg'




export const ArrowDownIcon = (props: SvgIconProps) => <SvgIcon component={ArrowDown} inheritViewBox {...props} />
export const CalendarIcon = (props: SvgIconProps) => <SvgIcon component={Calendar} inheritViewBox {...props} />
export const ErrorExclamationIcon = (props: SvgIconProps) => (
  <SvgIcon component={ErrorExclamation} inheritViewBox {...props} />
)
export const HomeIcon = (props: SvgIconProps) => <SvgIcon component={Home} inheritViewBox {...props} />
export const LogoIcon = (props: SvgIconProps) => <SvgIcon component={Logo} inheritViewBox {...props} />
export const LogOutIcon = (props: SvgIconProps) => <SvgIcon component={LogOut} inheritViewBox {...props} />
export const PowerConsumptionIcon = (props: SvgIconProps) => (
  <SvgIcon component={PowerConsumption} inheritViewBox {...props} />
)
export const ProfileIcon = (props: SvgIconProps) => <SvgIcon component={Profile} inheritViewBox {...props} />
export const StorybookIcon = (props: SvgIconProps) => <SvgIcon component={Storybook} inheritViewBox {...props} />
export const DownloadIcon = (props: SvgIconProps) => <SvgIcon component={Download} inheritViewBox {...props} />
export const CompareIcon = (props: SvgIconProps) => <SvgIcon component={Compare} inheritViewBox {...props} />
export const InvoiceIcon = (props: SvgIconProps) => <SvgIcon component={Invoice} inheritViewBox {...props} />
export const ContractConsumptionIcon = (props: SvgIconProps) => (
  <SvgIcon component={ContractConsumption} inheritViewBox {...props} />
)
export const ArrowRightIcon = (props: SvgIconProps) => <SvgIcon component={ArrowRight} inheritViewBox {...props} />
export const PlugIcon = (props: SvgIconProps) => <SvgIcon component={Plug} inheritViewBox {...props} />
export const CheckIcon = (props: SvgIconProps) => <SvgIcon component={Check} inheritViewBox {...props} />
export const LoaderIcon = (props: SvgIconProps) => <SvgIcon component={Loader} inheritViewBox {...props} />
export const CardEmptyCheckboxIcon = (props: SvgIconProps) => <SvgIcon component={CardEmptyCheckbox} inheritViewBox {...props} />
export const CardCheckedCheckboxIcon = (props: SvgIconProps) => <SvgIcon component={CardCheckedCheckbox} inheritViewBox {...props} />
export const ShareIcon = (props: SvgIconProps) => <SvgIcon component={Share} inheritViewBox {...props} />
export const InfoIcon = (props: SvgIconProps) => <SvgIcon component={Info} inheritViewBox {...props} />
export const AdditionIcon = (props: SvgIconProps) => <SvgIcon  {...props} sx={{transform: "rotate(45deg)"}} component={Multiplication} inheritViewBox />
export const MultiplicationIcon = (props: SvgIconProps) => <SvgIcon component={Multiplication} inheritViewBox {...props} />
export const DiscountPercentageIcon = (props: SvgIconProps) => <SvgIcon component={DiscountPercentage} inheritViewBox {...props} />
export const ClockIcon = (props: SvgIconProps) => <SvgIcon component={Clock} inheritViewBox {...props} />

