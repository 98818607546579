import { Button, Stack } from '@mui/material';
import LinkButton from 'components/ui/LinkButton';
import PageControls from 'components/ui/PageControls';
import { useTranslation } from 'react-i18next';

interface ConfigurationPageControlsProps {
    contractUuid?: string;
    isNextDisabled?: boolean;
}

const ConfigurationPageControls = ({ contractUuid, isNextDisabled }: ConfigurationPageControlsProps) => {
    const { t: commonTranslate } = useTranslation('common');
    const pageControlsStart = (
        <LinkButton to={`/customer/contract/${contractUuid}/new-offer/product-selection`} variant="text">
            {commonTranslate('BACK')}
        </LinkButton>
    );

    const pageControlsEnd = (
        <Stack gap={1} flexDirection={'row'}>
            <Button variant="outlined">{commonTranslate('SAVE_AND_CLOSE')}</Button>
            <LinkButton
                disabled={isNextDisabled}
                to={`/customer/contract/${contractUuid}/new-offer/offer-summary`}
                variant="contained"
            >
                {commonTranslate('NEXT')}
            </LinkButton>
        </Stack>
    );

    return <PageControls startControl={pageControlsStart} endControl={pageControlsEnd} />;
};

export default ConfigurationPageControls;
