import { palette, switchComponentBackground } from 'styles/theme/palette';

export function getConnectorColor(index: number, activeStep: number) {
    if (index < activeStep) {
        return palette.primary.main;
    } else if (index > activeStep) {
        return switchComponentBackground;
    } else {
        return palette.gradients.primary;
    }
}
