import { Stack, Typography, styled } from '@mui/material';
import { ArrowRightIcon, ClockIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { CardWrapper } from 'styles/styled-components/CardWrapper';
import SimpleIconTextBanner from '../SimpleIconTextBanner';
import OpenOfferCardInfoRow from './OpenOfferCardInfoRow';

interface OpenOfferCardProps {
    expirationDateTime?: string;
    type: string;
    contractName: string;
    electricityCost: number | string;
    powerSource: string;
    powerSourceCost: number | string;
    minsLeft: number;
}

const DOUBLE_DOT = ':';
const ICON_SIZE = 24;
const CLOCK_ICON_SIZE = 20;

const OpenOfferRightArrow = styled(ArrowRightIcon)(({ theme }) => ({
    width: ICON_SIZE,
    height: ICON_SIZE,
    fill: theme.palette.primary.main,
}));

const TimerClockIcon = styled(ClockIcon)(({ theme }) => ({
    width: CLOCK_ICON_SIZE,
    height: CLOCK_ICON_SIZE,
    fill: theme.palette.primary.main,
}));

const OpenOfferCard = ({
    expirationDateTime,
    contractName,
    electricityCost,
    powerSource,
    powerSourceCost,
    type,
    minsLeft,
}: OpenOfferCardProps) => {
    const { t } = useTranslation('contracts');
    const { t: commonT } = useTranslation('common');
    return (
        <CardWrapper gap={2} width={{ md: 467, sm: '100%' }}>
            <Stack gap={1}>
                <Stack gap={0.5}>
                    <Typography variant="bodySmall" color={(theme) => theme.palette.text.secondary}>
                        {expirationDateTime}
                    </Typography>
                    <Typography
                        display={'flex'}
                        alignItems={'center'}
                        variant="leadEmphasized"
                        color={(theme) => theme.palette.primary.main}
                    >
                        {type} <OpenOfferRightArrow />
                    </Typography>
                </Stack>
                <OpenOfferCardInfoRow label={t('OPEN_OFFER_CONTRACT')} value={contractName} />
                <OpenOfferCardInfoRow
                    label={t('OPEN_OFFER_ELECTRICITY_COST')}
                    value={commonT('RP_PER_KWH', { price: electricityCost })}
                />
                <OpenOfferCardInfoRow
                    label={powerSource + DOUBLE_DOT}
                    value={commonT('RP_PER_KWH', { price: powerSourceCost })}
                />
            </Stack>
            {/* <SimpleTimeLeftBanner minsLeft={minsLeft} />
             */}
            <SimpleIconTextBanner
                color="primary"
                text={commonT('VALID_FOR_MINUTES', { min: minsLeft })}
                icon={<TimerClockIcon />}
            />
        </CardWrapper>
    );
};

export default OpenOfferCard;
