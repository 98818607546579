import { Divider, Typography } from '@mui/material';
import ContractListing from 'components/pages/Contracts/ContractListing';
import OpenOffersSection from 'components/pages/Contracts/OpenOffersSection';
import RenewedContractListing from 'components/pages/Contracts/RenewedContractListing';
import FullwidthWrapper from 'components/wrappers/FullWidthWrapper';
import { useTranslation } from 'react-i18next';
import Container from 'styles/styled-components/Container';
import useContracts from 'view-controllers/pages/Contracts/useContracts.controller';

const Contracts = () => {
    const { activeContracts, openOffers, renewedContracts } = useContracts();
    const { t } = useTranslation('contracts');

    return (
        <Container size="xl">
            <Typography marginTop={6} variant="heading3">
                {t('MY_ELECTRICITY_CONTRACTS')}
            </Typography>
            <OpenOffersSection openOffers={openOffers} />
            <FullwidthWrapper>
                <Divider />
            </FullwidthWrapper>
            <ContractListing title={t('ACTIVE')} contracts={activeContracts} />
            <RenewedContractListing year="2025" contracts={renewedContracts} />
        </Container>
    );
};

export default Contracts;
