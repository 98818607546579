import { Stack, Typography } from '@mui/material';
import OpenOfferCard from 'components/ui/OpenOfferCard/OpenOfferCard';
import { OpenOffer } from 'data/mocks/contracts-mock';
import { useTranslation } from 'react-i18next';

// const mock: {
//     expirationDateTime?: string;
//     type: string;
//     contractName: string;
//     electricityCost: number;
//     powerSource: string;
//     powerSourceCost: number;
//     minsLeft: number;
// }[] = [
//     {
//         expirationDateTime: '16.01.2024, 16:30Uhr',
//         type: 'CKW Garant Fix',
//         contractName: 'Werk 2, Ebikon',
//         electricityCost: 22.13,
//         powerSource: 'HKN Wasser EU',
//         powerSourceCost: 5.13,
//         minsLeft: 29,
//     },
// ];

interface OpenOffersSectionProps {
    openOffers: OpenOffer[];
}

const OpenOffersSection = ({ openOffers }: OpenOffersSectionProps) => {
    const { t } = useTranslation('contracts');
    return (
        <Stack gap={4} mt={4} mb={6}>
            <Typography variant="heading4">{t('Offene Angebote')}</Typography>

            <Stack flexDirection={'row'} gap={3}>
                {openOffers.map((offer) => (
                    <OpenOfferCard key={offer.expirationDateTime} {...offer} />
                ))}
            </Stack>
        </Stack>
    );
};

export default OpenOffersSection;
