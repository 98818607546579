import { Box, Typography } from '@mui/material';
import Show from 'components/wrappers/Show';
import { sectionDescriptionSize, sectionNameSize, sectionTitleSize } from 'data/configs/section-basic-info';
import { Fragment } from 'react';

interface SectionBasicInfoProps {
    description?: string;
    name?: string;
    title: string;
    size?: 'lg' | 'sm' | 'xs';
}

const SectionBasicInfo = ({ description, name, title, size = 'lg' }: SectionBasicInfoProps) => {
    return (
        <Fragment>
            <Box>
                <Show condition={!!name}>
                    <Typography variant={sectionNameSize[size]} color={(theme) => theme.palette.text.secondary}>
                        {name}
                    </Typography>
                </Show>
                <Typography variant={sectionTitleSize[size]}>{title}</Typography>
            </Box>
            <Show condition={!!description}>
                <Typography variant={sectionDescriptionSize[size]}>{description}</Typography>
            </Show>
        </Fragment>
    );
};

export default SectionBasicInfo;
