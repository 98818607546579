import { Divider, Stack } from '@mui/material';

import ConfigurationIntroSection from 'components/pages/NewOffer/Configuration/ConfigurationIntroSection';
import ConfigurationPageControls from 'components/pages/NewOffer/Configuration/ConfigurationPageControls';
import ContractDurationSection from 'components/pages/NewOffer/Configuration/ContractDurationSection';
import CuponSection from 'components/pages/NewOffer/Configuration/CuponSection';
import PowerSourceSection from 'components/pages/NewOffer/Configuration/PowerSourceSection';
import SpotCompositionCostSection from 'components/pages/NewOffer/Configuration/SpotCompositionCostSection';
import FullwidthWrapper from 'components/wrappers/FullWidthWrapper';
import Show from 'components/wrappers/Show';

import useConfiguration from 'view-controllers/pages/NewOffer/useConfiguration.controller';

const Configuration = () => {
    const {
        contract,
        selectedProduct,
        contractDurationOptions,
        calculatedOffer,
        powerSourceOptions,
        isNextStepDisabled,
        selectedDuration,
        selectedPowerSource,
        handleDurationSelect,
        handlePowerSourceSelect,
    } = useConfiguration();

    return (
        <Stack mt={8} gap={5}>
            <ConfigurationIntroSection powerSource={contract?.powerSource} location={contract?.location} />
            <Show condition={selectedProduct === 'constant'}>
                <ContractDurationSection
                    selectedDuration={selectedDuration}
                    handleDurationSelect={handleDurationSelect}
                    contractDurationOptions={contractDurationOptions}
                />
            </Show>
            <Show condition={selectedProduct === 'variable'}>
                <SpotCompositionCostSection
                    fullCoverage={calculatedOffer?.fullCoverage}
                    riskPremium={calculatedOffer?.riskPremium}
                />
            </Show>
            <FullwidthWrapper>
                <Divider />
            </FullwidthWrapper>
            <PowerSourceSection
                powerSourceOptions={powerSourceOptions}
                selectedPowerSource={selectedPowerSource}
                handlePowerSourceSelect={handlePowerSourceSelect}
            />
            <CuponSection />
            <ConfigurationPageControls isNextDisabled={isNextStepDisabled} contractUuid={contract?.uuid} />
        </Stack>
    );
};

export default Configuration;
