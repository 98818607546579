import { styled, Stack } from '@mui/material';
import Footer from 'components/globals/Footer';
import Header from 'components/globals/Header/Header';

const AppWrapper = styled(Stack)({
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
});
const PageWrapper = styled(Stack)({
    flexGrow: 1,
});

interface IPageContainer {
    children: React.ReactNode;
}

const PageContainer = ({ children }: IPageContainer) => {
    return (
        <AppWrapper>
            <Header />
            <PageWrapper>{children}</PageWrapper>
            <Footer />
        </AppWrapper>
    );
};

export default PageContainer;
