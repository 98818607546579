import { List, ListItem, ListItemText } from '@mui/material';
import { Route } from 'data/routes/navigation-routes';
import { useTranslation } from 'react-i18next';
import { NavItem } from 'styles/styled-components/NavItem';

interface MobileNavItemsProps {
    navRoutes: Route[];
    toggleDrawer: () => void;
}

const MobileNavItems = ({ navRoutes, toggleDrawer }: MobileNavItemsProps) => {
    const { t } = useTranslation('header');

    return (
        <List>
            {navRoutes.map(({ path, label }) => (
                <ListItem key={label} sx={{ p: 0, marginTop: 1 }}>
                    <NavItem to={path} onClick={toggleDrawer}>
                        <ListItemText sx={{ margin: 0 }}>{t(label)}</ListItemText>
                    </NavItem>
                </ListItem>
            ))}
        </List>
    );
};

export default MobileNavItems;
