import GlobalProviders from 'components/wrappers/GlobalProviders';
import PageContainer from 'components/wrappers/PageContainer';
import useMockSeeder from 'hooks/useMockSeeder';
import Main from 'routers/Main';

function App() {
    useMockSeeder();
    return (
        <GlobalProviders>
            <PageContainer>
                <Main />
            </PageContainer>
        </GlobalProviders>
    );
}

export default App;
