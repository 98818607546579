import { Stack, Typography } from '@mui/material';

interface OpenOfferCardInfoRowProps {
    label: string;
    value: string;
}

const OpenOfferCardInfoRow = ({ label, value }: OpenOfferCardInfoRowProps) => (
    <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="bodySmall" color={(theme) => theme.palette.text.secondary}>
            {label}
        </Typography>
        <Typography variant="bodySmallEmphasized">{value}</Typography>
    </Stack>
);

export default OpenOfferCardInfoRow;
