import Contracts from 'pages/Contracts/Contracts';
import RenewContract from 'pages/Contracts/RenewContract';
import CalculactionLoading from 'pages/NewOffer/CalculactionLoading';
import NewOffer from 'pages/NewOffer/NewOffer';
import NewOfferSuccess from 'pages/NewOffer/NewOfferSuccess';

export default [
    {
        path: '/contract/:contractId/new-offer/*',
        component: <NewOffer />,
    },
    {
        path: '/contract/:contractId/calculate/new-offer',
        component: <CalculactionLoading />,
    },
    {
        path: '/contract/:contractId/new-offer/success',
        component: <NewOfferSuccess />,
    },
    {
        path: '/electricity-contracts',
        component: <Contracts />,
        exact: true,
    },
    {
        path: '/renew-contract/:contractId',
        component: <RenewContract />,
        exact: true,
    },
];
