import { Box, Stack, Typography, styled } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons';
import AttributeItem from 'components/ui/AttributeItem';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { useTranslation } from 'react-i18next';
import { CardWrapper } from 'styles/styled-components/CardWrapper';
import CardCheckBox from '../CardCheckBox';
import GraphLegendItem from './GraphLegendItem';
import { graphLegendPaletter } from 'styles/theme/palette';
import { ProductSelected } from 'store/newOfferFlow';

interface ProductSelectionCardProps {
    type: ProductSelected;
    typeTitle: string;
    costTitle: string;
    description: string;
    attributes: string[];
    imageSrc: string;
    isSelected?: boolean;
    onProductSelect: (product: ProductSelected) => void;
}

const ImageWrapper = styled(Box)({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
});

const GraphLegend = styled(Stack)({
    position: 'absolute',
    bottom: '12px',
    left: '12px',
});

const ProductSelectionCard = ({
    type,
    typeTitle,
    attributes,
    costTitle,
    description,
    imageSrc,
    isSelected,
    onProductSelect,
}: ProductSelectionCardProps) => {
    const { t } = useTranslation('product-selection-card');
    return (
        <CardWrapper width={'471px'}>
            <Stack flexGrow={1} gap={3} justifyContent={'space-between'}>
                <Stack flexGrow={1} gap={1}>
                    <SectionBasicInfo name={typeTitle} title={costTitle} description={description} size="sm" />
                    <Stack flexGrow={1} gap={1.5}>
                        {attributes.map((attribute, index) => (
                            <AttributeItem size="sm" key={index} label={attribute} />
                        ))}
                    </Stack>
                </Stack>
                <ImageWrapper>
                    <img src={imageSrc} alt={typeTitle} />
                    <GraphLegend>
                        <GraphLegendItem label={t('MARKET_PRICE')} color={graphLegendPaletter.green} />
                        <GraphLegendItem label={t('YOUR_PRICE')} color={graphLegendPaletter['chart-violet-dark']} />
                    </GraphLegend>
                </ImageWrapper>
                <Typography
                    color={(theme) => theme.palette.primary.main}
                    variant="leadEmphasized"
                    display={'flex'}
                    gap={0.5}
                    alignItems={'center'}
                >
                    {t('MORE_INFO')} <ArrowRightIcon />
                </Typography>

                <CardCheckBox checked={isSelected} onClick={() => onProductSelect(type)} />
            </Stack>
        </CardWrapper>
    );
};

export default ProductSelectionCard;
