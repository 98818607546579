import i18n from 'i18n';

export const getExistingCustomerNewOfferSteps = () => [
    i18n.t('new-offer:STEP_1'),
    i18n.t('new-offer:STEP_2'),
    i18n.t('new-offer:STEP_3'),
    i18n.t('new-offer:STEP_4'),
];

export const stepUrls = {
    'power-consumption': 0,
    'product-selection': 1,
    configuration: 2,
    'offer-summary': 3,
};
