import { useTranslation } from 'react-i18next';
import SimpleIconTextBanner from '../SimpleIconTextBanner';
import { styled } from '@mui/material';
import { CheckIcon, ClockIcon, PlugIcon } from 'assets/icons';
import { contractExpiresIn } from 'utils/contract-translation-formaters';

const CHECK_ICON_WIDTH = 16.78;
const CHECK_ICON_HEIGHT = 11.67;
const CLOCK_ICON_SIZE = 20;

const ContractCardCheckIcon = styled(CheckIcon)(({ theme }) => ({
    width: CHECK_ICON_WIDTH,
    height: CHECK_ICON_HEIGHT,
    fill: theme.palette.primary.main,
}));

const TimerClockIcon = styled(ClockIcon)(({ theme }) => ({
    width: CLOCK_ICON_SIZE,
    height: CLOCK_ICON_SIZE,
    fill: theme.palette.primary.main,
}));

interface ContractCardBannerProps {
    isNotStarted?: boolean;
    supplyStartDate?: string;
    supplyEndDate?: string;
    hasBeenRenewed?: boolean;
}

const ContractCardBanner = ({
    isNotStarted,
    hasBeenRenewed,
    supplyStartDate,
    supplyEndDate,
}: ContractCardBannerProps) => {
    const { t } = useTranslation('contract-card');

    if (isNotStarted) {
        return (
            <SimpleIconTextBanner icon={<TimerClockIcon />} text={t('CONTRACT_STARTS_AT', { date: supplyStartDate })} />
        );
    }

    if (hasBeenRenewed) {
        return (
            <SimpleIconTextBanner
                icon={<ContractCardCheckIcon />}
                color="primary"
                text={t('CONTRACT_FOLLOW_UP_CONCLUDED')}
            />
        );
    }

    return <SimpleIconTextBanner icon={<PlugIcon />} text={contractExpiresIn(supplyEndDate)} />;
};

export default ContractCardBanner;
