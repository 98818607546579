import LinkButton from 'components/ui/LinkButton';
import PageControls from 'components/ui/PageControls';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RenewContractPageControlsProps {
    contractUuid: string;
}

const RenewContractPageControls = ({ contractUuid }: RenewContractPageControlsProps) => {
    const { t } = useTranslation('common');
    return (
        <PageControls
            startControl={
                <LinkButton to="/customer/electricity-contracts" variant="text">
                    {t('CANCEL')}
                </LinkButton>
            }
            endControl={
                <LinkButton to={`/customer/contract/${contractUuid}/new-offer/power-consumption`}>
                    {t('START')}
                </LinkButton>
            }
        />
    );
};

export default RenewContractPageControls;
