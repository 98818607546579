import { useEffect } from 'react';
import useAuthStore from 'store/authStore';

const useMockSeeder = () => {
    const { setUser } = useAuthStore();

    // Seed auth store with in session mock user
    useEffect(() => {
        setUser({
            no: '229378632',
            companyName: 'Muster AG',
            companyInvoiceEmail: 'invoices@musterag.com',
            address: { street: 'Zurlindenstrasse 12', zip: '6003', city: 'Luzern' },
            contact: {
                fullName: 'Max Mustermann',
                phoneNumber: '041 123 45 67',
                email: 'max.mustermann@gmail.com',
            },
        });
    }, [setUser]);

    return;
};

export default useMockSeeder;
