import { create } from 'zustand';

interface AppSettingsStore {
    hideNavigation: boolean;
    toggleNavigation: () => void;
}

const useAppSettingsStore = create<AppSettingsStore>((set) => ({
    hideNavigation: false,
    toggleNavigation: () => set((state) => ({ hideNavigation: !state.hideNavigation })),
}));

export default useAppSettingsStore;
