import { Typography, TypographyProps, styled } from '@mui/material';
import { iconTextBannerPalette } from 'styles/theme/palette';

type BannerColorScheme = 'primary' | 'neutral';

const SimpleTimeLeftBannerWrapper = styled(Typography)<{ colorScheme?: BannerColorScheme }>(
    ({ theme, colorScheme = 'neutral' }) => ({
        color: colorScheme === 'primary' ? theme.palette.primary.main : 'unset',
        backgroundColor: iconTextBannerPalette[colorScheme].background,
        display: 'flex',
        border: `1px solid ${iconTextBannerPalette[colorScheme].border}`,
        borderRadius: 24,
        padding: '4px  12px',
        width: 'fit-content',
        gap: '0.5rem',
        alignItems: 'center',
    }),
);

interface SimpleIconTextBannerBannerProps extends TypographyProps {
    icon: React.ReactNode;
    text: string;
    color?: BannerColorScheme;
}

const SimpleIconTextBanner = ({ text, icon, color }: SimpleIconTextBannerBannerProps) => {
    return (
        <SimpleTimeLeftBannerWrapper variant="captionEmphasized" colorScheme={color}>
            {icon} {text}
        </SimpleTimeLeftBannerWrapper>
    );
};

export default SimpleIconTextBanner;
