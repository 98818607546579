import { Stack } from '@mui/material';
import PowerConsumptionDialogContent from 'components/pages/NewOffer/PowerConsumption/PowerConsumptionDialogContent';
import PowerConsumptionIntroSection from 'components/pages/NewOffer/PowerConsumption/PowerConsumptionIntroSection';
import PowerConsumptionPageControls from 'components/pages/NewOffer/PowerConsumption/PowerConsumptionPageControls';
import DCPModal from 'components/ui/DCPModal';
import { useTranslation } from 'react-i18next';
import usePowerConsumption from 'view-controllers/pages/NewOffer/usePowerConsumption.controller';

const PowerConsumption = () => {
    const { contract, isDialogOpen, toggleDialog } = usePowerConsumption();
    const { t } = useTranslation('power-consumption');

    return (
        <Stack flexGrow={1} justifyContent={'space-between'}>
            <PowerConsumptionIntroSection
                location={contract?.location}
                powerSource={contract?.powerSource}
                toggleDialog={toggleDialog}
            />
            <PowerConsumptionPageControls contractUuid={contract?.uuid} />
            <DCPModal title={t('DIALOG_TITLE')} open={isDialogOpen} maxWidth="md" onClose={toggleDialog}>
                <PowerConsumptionDialogContent />
            </DCPModal>
        </Stack>
    );
};

export default PowerConsumption;
