import { Box, Stack } from '@mui/material';
import DCPCheckbox from 'components/ui/DCPCheckbox';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { useTranslation } from 'react-i18next';
import AttributeItem from '../../../ui/AttributeItem';

interface ContractInfoProps {
    contractTitle: string;
}

const attributes = ['CONTRACT_ATTRIBUTE_1', 'CONTRACT_ATTRIBUTE_2', 'CONTRACT_ATTRIBUTE_3'];

const ContractInfo = ({ contractTitle }: ContractInfoProps) => {
    const { t } = useTranslation('renew-contract');
    const { t: commonT } = useTranslation('common');

    return (
        <Box mb={'auto'}>
            <Stack gap={3}>
                <SectionBasicInfo name={contractTitle} title={t('TITLE')} description={t('DESCRIPTION')} />
                <Stack gap={2}>
                    {attributes.map((attribute, index) => (
                        <AttributeItem key={index} label={t(attribute)} />
                    ))}
                </Stack>
            </Stack>
            <DCPCheckbox sx={{ mt: 5 }} label={commonT('DONT_SHOW_AGAIN')} />
        </Box>
    );
};

export default ContractInfo;
