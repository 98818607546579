import LinkButton from 'components/ui/LinkButton';
import PageControls from 'components/ui/PageControls';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PowerConsumptionPageControlsProps {
    contractUuid?: string;
}

const PowerConsumptionPageControls = ({ contractUuid }: PowerConsumptionPageControlsProps) => {
    const { t: commonT } = useTranslation('common');
    return (
        <PageControls
            startControl={
                <LinkButton to={`/customer/renew-contract/${contractUuid}`} variant="text">
                    {commonT('BACK')}
                </LinkButton>
            }
            endControl={
                <LinkButton to={`/customer/contract/${contractUuid}/calculate/new-offer`} variant="contained">
                    {commonT('NEXT')}
                </LinkButton>
            }
        />
    );
};

export default PowerConsumptionPageControls;
