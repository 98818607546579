import { Divider, Stack, Typography } from '@mui/material';
import PricingSectionRow from './PricingSectionRow';
import { useTranslation } from 'react-i18next';
import { ContractDurationOption, PowerSourceOption } from 'data/configs/new-offer-configuration';
import PricingTotalPriceRow from './PricingTotalPriceRow';

interface AnnualPricingViewProps {
    durationInfo?: ContractDurationOption;
    powerSourceInfo?: PowerSourceOption;
}

interface AnnualPricePerKwh {
    period: string;
    pricePerKwh: number | string;
}

const AnnualPricingRow = ({ period, pricePerKwh }: AnnualPricePerKwh) => {
    const { t: commonT } = useTranslation('common');
    return (
        <Stack justifyContent={'space-between'} flexDirection={{ md: 'row', sm: 'column' }}>
            <Typography variant="bodySmall">{period}</Typography>
            <Typography variant="bodyRegularEmphasized">{commonT('RP_PER_KWH', { price: pricePerKwh })}</Typography>
        </Stack>
    );
};

const AnnualPricingView = ({ durationInfo, powerSourceInfo }: AnnualPricingViewProps) => {
    const { t } = useTranslation('offer-summary');
    const { t: commonT } = useTranslation('common');
    return (
        <Stack gap={3}>
            <Stack gap={1}>
                <PricingSectionRow title={t('PRICING_SECTION_ELECTRICITY_PRODUCT')} value={durationInfo?.title} />
                {durationInfo?.annualPricePerKwh?.map((annualPricePerKwh) => (
                    <AnnualPricingRow
                        key={annualPricePerKwh.period}
                        period={annualPricePerKwh.period}
                        pricePerKwh={annualPricePerKwh.pricePerKwh}
                    />
                ))}
            </Stack>
            <Divider />
            <PricingSectionRow
                title={t('PRICING_SECTION_POWER_SOURCE')}
                value={powerSourceInfo?.title}
                pricePerKW={commonT('RP_PER_KWH', { price: powerSourceInfo?.pricePerKwh })}
                pricePerYear={commonT('PRICE_PER_YEAR', { price: powerSourceInfo?.pricePerYear })}
            />
            <Divider />
            <Stack gap={1}>
                <PricingTotalPriceRow />
                {durationInfo?.annualPricePerKwh?.map((annualPricePerKwh) => (
                    <AnnualPricingRow
                        key={annualPricePerKwh.period}
                        period={annualPricePerKwh.period}
                        pricePerKwh={Number(
                            (Number(powerSourceInfo?.pricePerKwh) + Number(annualPricePerKwh?.pricePerKwh)).toFixed(2),
                        )}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export default AnnualPricingView;
