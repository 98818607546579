import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { palette } from 'styles/theme/palette';
import { Stack } from '@mui/material';
import Show from 'components/wrappers/Show';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    sx={{
                        height: '12px',
                        backgroundColor: palette.background['neuatral-medium'],
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: palette.illustration['illustration-400'],
                        },
                    }}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

interface LinearWithValueLabelProps {
    title?: string;
    progress: number;
    timeLeftLabel?: string;
}

export default function DCPProgressBar({ progress, timeLeftLabel, title }: LinearWithValueLabelProps) {
    return (
        <Stack gap={2}>
            <Show condition={title ? true : false}>
                <Typography variant="bodyRegularEmphasized">{title}</Typography>
            </Show>
            <Stack gap={0.5} sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={progress} />
                {timeLeftLabel && (
                    <Typography variant="bodySmall" color={(theme) => theme.palette.text.secondary}>
                        {timeLeftLabel}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}
