import { Stack, Typography, styled } from '@mui/material';
import Show from 'components/wrappers/Show';
import { ProductSelected } from 'store/newOfferFlow';
import ConstBasicInfo from './ConstBasicInfo';
import VariableBasicInfo from './VariableBasicInfo';
import { palette } from 'styles/theme/palette';
import { useTranslation } from 'react-i18next';

interface OfferBasicInfoSectionProps {
    viewType: ProductSelected | null;
    variableBasicInfo?: { fullCoverage?: number | string; riskPremium?: number | string };
    contractStartDate?: string;
    contractEndDate?: string;
    expectedConsumption?: number | string;
}

const BasicInfoWrapper = styled(Stack)({
    padding: '24px 32px',
    backgroundColor: palette.background['neuatral-medium'],
    borderRadius: 1,
    marginTop: 48,
});

const OfferBasicInfoSection = ({
    viewType,
    variableBasicInfo,
    contractStartDate,
    contractEndDate,
    expectedConsumption,
}: OfferBasicInfoSectionProps) => {
    const { t } = useTranslation('offer-summary');
    return (
        <Stack gap={2}>
            <BasicInfoWrapper>
                <Show condition={viewType === 'constant'}>
                    <ConstBasicInfo startDate={contractStartDate} endDate={contractEndDate} />
                </Show>
                <Show condition={viewType === 'variable'}>
                    <VariableBasicInfo
                        fullCoverage={variableBasicInfo?.fullCoverage}
                        riskPremium={variableBasicInfo?.riskPremium}
                        contractStartDate={contractStartDate}
                        expectedConsumption={expectedConsumption}
                    />
                </Show>
            </BasicInfoWrapper>
            <Show condition={viewType === 'variable'}>
                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                    {t('SPOT_PRICE_NOTE')}
                </Typography>
            </Show>
        </Stack>
    );
};

export default OfferBasicInfoSection;
