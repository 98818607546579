import { useCallback, useEffect } from 'react';
import useAppSettingsStore from 'store/appSettingsStore';

const useToggleNavigation = () => {
    const { toggleNavigation } = useAppSettingsStore();

    const handleToggleNavigation = useCallback(() => {
        toggleNavigation();
    }, [toggleNavigation]);

    useEffect(() => {
        handleToggleNavigation();

        return () => {
            handleToggleNavigation();
        };
    }, [handleToggleNavigation]);

    return;
};

export default useToggleNavigation;
