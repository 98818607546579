import { Box, SxProps, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TimerWrapper = styled(Box)(({ theme }) => ({
    width: '225px',
    padding: '12px 24px',
    backgroundColor: '#D7EEF7',
    border: '1px solid #83D0F5',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

interface TimerBannerProps {
    validTill: string;
    timeLeft: number;
    sx?: SxProps;
}

const TimerBanner = ({ timeLeft, validTill, sx }: TimerBannerProps) => {
    const { t } = useTranslation('configuration');

    return (
        <TimerWrapper sx={sx}>
            <Typography variant="caption" color={(theme) => theme.palette.text.primary}>
                {t('OFFER_VALID_UNTIL')}
            </Typography>
            <Typography sx={{ textWrap: 'nowrap' }} variant="leadEmphasized">
                {t('OFFER_EXPIRES_AT', { at: validTill })}
            </Typography>
            <Typography variant="caption">{t('OFFER_MINS_LEFT', { minutes: timeLeft })}</Typography>
        </TimerWrapper>
    );
};

export default TimerBanner;
