import useAppSettingsStore from 'store/appSettingsStore';

const useHeader = () => {
    const { hideNavigation } = useAppSettingsStore();

    const isNavigationHidden = hideNavigation;

    return { isNavigationHidden };
};

export default useHeader;
