import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferBasicInfoRow from './OfferBasicInfoRow';
import { InfoIcon } from 'assets/icons';

interface ConstBasicInfoProps {
    startDate?: string;
    endDate?: string;
}

const INFO_ICON_SIZE = '16.67px';

const OfferInfoIcon = styled(InfoIcon)({
    width: INFO_ICON_SIZE,
    height: INFO_ICON_SIZE,
});

const ConstBasicInfo = ({ startDate, endDate }: ConstBasicInfoProps) => {
    const { t } = useTranslation('offer-summary');
    return (
        <Stack>
            <OfferBasicInfoRow
                label={t('CONTRACT_DURATION')}
                value={
                    <Typography display={'flex'} alignItems={'center'} gap={1}>
                        {t('CONTRACT_DURATION_VALUE', { startDate: startDate, endDate: endDate })}
                    </Typography>
                }
            />
            <OfferBasicInfoRow
                label={t('EXPECTED_SUPPLY_QUANTITY')}
                value={
                    <Typography display={'flex'} alignItems={'center'} gap={1}>
                        {t('EXPECTED_SUPPLY_QUANTITY_VALUE', { value: "124'654" })} <OfferInfoIcon />
                    </Typography>
                }
            />
        </Stack>
    );
};

export default ConstBasicInfo;
