import { Stack } from '@mui/material';

import ContractInfo from 'components/pages/Contracts/RenewContract/ContractInfo';
import RenewContractPageControls from 'components/pages/Contracts/RenewContract/RenewContractPageControls';
import Container from 'styles/styled-components/Container';
import useRenewContract from 'view-controllers/pages/Contracts/useRenewContract.controller';

const RenewContract = () => {
    const { currentContract } = useRenewContract();

    return (
        <Container size="md" sx={{ my: 6 }}>
            <Stack flexGrow={1} justifyContent={'space-between'}>
                <ContractInfo contractTitle={`${currentContract.powerSource}, ${currentContract.location}`} />
            </Stack>
            <RenewContractPageControls contractUuid={currentContract.uuid} />
        </Container>
    );
};

export default RenewContract;
