import { getContractDurationConfigurations, getPowerSourceConfigurations } from 'data/configs/new-offer-configuration';
import { powerSourceMock } from 'data/mocks/configuration-mocks';
import { useMemo } from 'react';
import useAuthStore from 'store/authStore';
import useNewOfferFlowStore from 'store/newOfferFlow';

const useOfferSummary = () => {
    const { contract, calculatedOffer, selectedProduct, selectedDuration, selectedPowerSource } =
        useNewOfferFlowStore();

    const { user } = useAuthStore();

    const offerPricingViewType = selectedProduct === 'variable' ? selectedProduct : selectedDuration;

    const selectedDurationInfo = useMemo(
        () => getContractDurationConfigurations().find((config) => config.type === selectedDuration),
        [selectedDuration],
    );

    const selectedPowerSourceInfo = useMemo(
        () => getPowerSourceConfigurations(powerSourceMock).find((config) => config.type === selectedPowerSource),
        [selectedPowerSource],
    );

    return {
        contract,
        calculatedOffer,
        selectedProduct,
        offerPricingViewType,
        selectedDurationInfo,
        selectedPowerSourceInfo,
        customer: user,
    };
};

export default useOfferSummary;
