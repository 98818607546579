import React from 'react';
import { useTranslation } from 'react-i18next';
import ContractInfoRow from './ContractInfoRow';

interface ContractCardInfoSectionProps {
    streetAddress?: string;
    postalCode?: string;
    city?: string;
    meterId: string;
    contractNo: string;
    product?: string;
    proofOfOrigin?: string;
    supplyStartDate?: string;
    supplyEndDate?: string;
}

const ContractCardInfoSection = ({
    streetAddress,
    postalCode,
    city,
    meterId,
    contractNo,
    product,
    proofOfOrigin,
    supplyStartDate,
    supplyEndDate,
}: ContractCardInfoSectionProps) => {
    const { t } = useTranslation('contract-card');
    return (
        <>
            <ContractInfoRow label={t('CONTRACT_ADDRESS')} value={`${streetAddress}, ${postalCode} ${city}`} />
            <ContractInfoRow label={t('CONTRACT_METER')} value={meterId} />
            <ContractInfoRow label={t('CONTRACT_NUMBER')} value={contractNo} />
            <ContractInfoRow
                label={t('CONTRACT_PRODUCT_AND_PROOF_OF_ORIGIN')}
                value={`${product}, ${proofOfOrigin}`}
                accentValue
            />
            <ContractInfoRow label={t('CONTRACT_TERM')} value={`${supplyStartDate} - ${supplyEndDate}`} />
        </>
    );
};

export default ContractCardInfoSection;
