import { Typography, styled } from '@mui/material';

const CardBanner = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.primary.dark,
    top: -20,
    height: '41px',
    color: theme.palette.common.white,
    borderRadius: '32px',
    padding: '6px 16px',
}));

CardBanner.defaultProps = {
    variant: 'bodyRegularEmphasized',
};

export default CardBanner;
