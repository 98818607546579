import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PowerConsumptionDialogContent = () => {
    const { t } = useTranslation('power-consumption');

    return (
        <Stack gap={4} my={4}>
            <Typography variant="bodyRegular" color={(theme) => theme.palette.text.primary} maxWidth={'656px'}>
                {t('DIALOG_DESCRIPTION')}
            </Typography>
            <Stack gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
                <Button>{t('DIALOG_BUTTON_APPOINTMENT')}</Button>
                <Button variant="outlined">{t('DIALOG_BUTTON_SPOTMARKET')}</Button>
            </Stack>
        </Stack>
    );
};

export default PowerConsumptionDialogContent;
