export type Contract = {
    uuid: string;
    contractNo: string;
    streetAddress: string;
    postalCode?: string;
    city?: string;
    meterId: string;
    powerSource: string;
    location: string;
    supplyStartDate?: string;
    supplyEndDate?: string;
    product: string;
    proofOfOrigin: string;
    hasBeenRenewed?: boolean;
};

export const currentUserActiveContractsMock: Contract[] = [
    {
        uuid: '1',
        contractNo: '30537395',
        streetAddress: 'Zurlindenstrasse 12',
        postalCode: '6003',
        city: 'Luzern',
        meterId: 'CH1003601234500000000000005233793',
        powerSource: 'Werk 1',
        location: 'Luzern',
        supplyStartDate: '01.10.2022',
        supplyEndDate: '01.10.2024',
        product: 'CKW Garant Fix',
        proofOfOrigin: 'HKN Wasser EU',
    },
    {
        uuid: '2',
        contractNo: '30537394',
        streetAddress: 'Sonnmattstrasse 13',
        postalCode: '6030',
        city: 'Ebikon',
        meterId: 'CH1003601234500000000000005233714',
        powerSource: 'Werk 2',
        location: 'Ebikon',
        supplyStartDate: '25.10.2023',
        supplyEndDate: '25.10.2026',
        product: 'CKW Garant Fix',
        proofOfOrigin: 'HKN Wasser EU',
    },
];

export const renewContractMock = {
    uuid: '2',
    contractNo: '30537394',
    streetAddress: 'Sonnmattstrasse 13',
    postalCode: '6030',
    city: 'Ebikon',
    meterId: 'CH1003601234500000000000005233714',
    powerSource: 'Werk 2',
    location: 'Ebikon',
    supplyStartDate: '25.10.2023',
    supplyEndDate: '25.10.2026',
    product: 'CKW Garant Fix',
    proofOfOrigin: 'HKN Wasser EU',
};

export const getContractById = (uuid: string): Contract | undefined => {
    return currentUserActiveContractsMock.find((contract) => contract.uuid === uuid);
};

export type OpenOffer = {
    expirationDateTime?: string;
    type: string;
    contractName: string;
    electricityCost: number;
    powerSource: string;
    powerSourceCost: number;
    minsLeft: number;
};

export const openOffersMock: OpenOffer[] = [
    {
        expirationDateTime: '16.01.2024, 16:30Uhr',
        type: 'CKW Garant Fix',
        contractName: 'Werk 2, Ebikon',
        electricityCost: 22.13,
        powerSource: 'HKN Wasser EU',
        powerSourceCost: 5.13,
        minsLeft: 29,
    },
];

export const renewedContractList: Contract[] = [
    {
        uuid: '1',
        contractNo: '30537395',
        streetAddress: 'Zurlindenstrasse 12',
        postalCode: '6003',
        city: 'Luzern',
        meterId: 'CH1003601234500000000000005233793',
        powerSource: 'Werk 1',
        location: 'Luzern',
        supplyStartDate: '01.10.2024',
        supplyEndDate: '01.10.2026',
        product: 'CKW Garant Fix',
        proofOfOrigin: 'HKN Wasser EU',
    },
];
