import { Stack, Typography } from '@mui/material';
import PowerSourceCard from 'components/ui/PowerSourceCard';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { PowerSourceOption, PowerSourceType } from 'data/configs/new-offer-configuration';
import { useTranslation } from 'react-i18next';

interface PowerSourceSectionProps {
    powerSourceOptions: PowerSourceOption[];
    selectedPowerSource?: PowerSourceType | null;
    handlePowerSourceSelect: (powerSource: PowerSourceType) => void;
}

const PowerSourceSection = ({
    powerSourceOptions,
    selectedPowerSource,
    handlePowerSourceSelect,
}: PowerSourceSectionProps) => {
    const { t } = useTranslation('configuration');
    return (
        <Stack gap={5}>
            <Stack gap={1}>
                <SectionBasicInfo
                    size="sm"
                    title={t('POWER_SOURCE_TITLE')}
                    description={t('POWER_SOURCE_DESCRIPTION')}
                />
            </Stack>
            <Stack gap={2} direction="row" flexWrap={'wrap'}>
                {powerSourceOptions.map((powerSourceOption) => (
                    <PowerSourceCard
                        key={powerSourceOption.type}
                        {...powerSourceOption}
                        onClick={handlePowerSourceSelect}
                        isSelected={powerSourceOption.type === selectedPowerSource}
                    />
                ))}
            </Stack>
            <Typography variant={'caption'} color={'text.secondary'}>
                {t('SECTION_NOTE', { consumption: "124'654" })}
            </Typography>
        </Stack>
    );
};

export default PowerSourceSection;
