import { Box } from '@mui/material';
import { getConnectorColor } from 'utils/stepper-formaters';

interface ConnectorProps {
    index: number;
    activeStep: number;
}

const Connector = ({ index, activeStep }: ConnectorProps) => (
    <Box
        data-testid="connector"
        sx={{ background: getConnectorColor(index, activeStep) }}
        flexGrow={1}
        height={'2px'}
    />
);
export default Connector;
