/* istanbul ignore file */
// Reason: testing Trans component is not working properly
import { Divider, Stack, Typography } from '@mui/material';
import ContractOverviewSection from 'components/pages/NewOffer/OfferSummary/ContractOverviewSection';
import CustomerInfoSection from 'components/pages/NewOffer/OfferSummary/CustomerInfoSection';
import InfoBannerContent from 'components/pages/NewOffer/OfferSummary/InfoBannerContent';
import OfferBasicInfoSection from 'components/pages/NewOffer/OfferSummary/OfferBasicInfoSection';
import OfferPricingSection from 'components/pages/NewOffer/OfferSummary/OfferPricingSection';
import OfferSummaryPageControls from 'components/pages/NewOffer/OfferSummary/OfferSummaryPageControls';
import DCPCheckbox from 'components/ui/DCPCheckbox';
import Show from 'components/wrappers/Show';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'styles/styled-components/Container';
import useOfferSummary from 'view-controllers/pages/NewOffer/useOfferSummary.controller';

const OfferSummary = () => {
    const {
        contract,
        calculatedOffer,
        selectedProduct,
        offerPricingViewType,
        selectedDurationInfo,
        selectedPowerSourceInfo,
        customer,
    } = useOfferSummary();

    const { t } = useTranslation('offer-summary');

    return (
        <Stack>
            <Container size="md">
                <Stack mt={8} gap={5}>
                    <ContractOverviewSection
                        contractMeter={contract?.meterId}
                        contractName={`${contract?.powerSource}, ${contract?.location}`}
                        contractLocation={'Zurlindenstrasse 12, 6003 Luzern'}
                        contractOfferNO={calculatedOffer?.no}
                        contractRevisionId={calculatedOffer?.revisionId}
                    />
                </Stack>
                <OfferBasicInfoSection
                    variableBasicInfo={{
                        fullCoverage: calculatedOffer?.fullCoverage,
                        riskPremium: calculatedOffer?.riskPremium,
                    }}
                    viewType={selectedProduct}
                    contractStartDate={calculatedOffer?.startDate}
                    contractEndDate={calculatedOffer?.endDate}
                />
                <OfferPricingSection
                    viewType={offerPricingViewType}
                    selectedDurationInfo={selectedDurationInfo}
                    selectedPowerSourceInfo={selectedPowerSourceInfo}
                />
                <Divider />
                <CustomerInfoSection customer={customer} />
                <Divider sx={{ my: 4 }} />
                <Show condition={selectedProduct === 'constant'}>
                    <InfoBannerContent />
                </Show>
                <DCPCheckbox
                    sx={{ mb: 8 }}
                    label={
                        <Trans components={{ mainColor: <Typography color={(theme) => theme.palette.primary.main} /> }}>
                            {t('ACCEPT_AGB')}
                        </Trans>
                    }
                />
            </Container>
            <OfferSummaryPageControls contractUuid={contract?.uuid} />
        </Stack>
    );
};

export default OfferSummary;
