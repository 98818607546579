import { Box, Stack, Typography, styled } from '@mui/material';

interface GraphLegendItemProps {
    label: string;
    color: string;
}

const ItemColorBox = styled(Box)<{ color: string }>(({ color }) => ({
    width: '18px',
    height: '3px',
    backgroundColor: color,
}));

const GraphLegendItem = ({ color, label }: GraphLegendItemProps) => {
    return (
        <Stack alignItems={'center'} gap={1} flexDirection={'row'}>
            <ItemColorBox color={color} />
            <Typography variant="captionSmall" color={(theme) => theme.palette.text.primary}>
                {label}
            </Typography>
        </Stack>
    );
};

export default GraphLegendItem;
