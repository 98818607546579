import { Container as MuiContainer, styled } from '@mui/material';

const sizes = {
    sm: '365px',
    md: '784px',
    lg: '992px',
    xl: '1200px',
    default: '100%',
};

const Container = styled(MuiContainer)<{ size?: string }>(({ size = 'default', theme }) => ({
    maxWidth: sizes[size],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
        width: sizes.default,
    },
    // unset all breakpoints from mui container
    [theme.breakpoints.up('lg')]: {
        padding: 0,
        maxWidth: sizes[size],
    },
    [theme.breakpoints.up('xl')]: {
        padding: 0,
        maxWidth: sizes[size],
    },
}));

export default Container;
