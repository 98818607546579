import {
    ContractDurationType,
    PowerSourceType,
    getContractDurationConfigurations,
    getPowerSourceConfigurations,
} from 'data/configs/new-offer-configuration';
import { powerSourceMock } from 'data/mocks/configuration-mocks';
import useNewOfferFlowStore from 'store/newOfferFlow';

const useConfiguration = () => {
    const {
        contract,
        selectedProduct,
        calculatedOffer,
        selectedDuration,
        selectedPowerSource,
        setSelectedDuration,
        setSelectedPowerSource,
    } = useNewOfferFlowStore();

    const powerSourceOptions = getPowerSourceConfigurations(powerSourceMock);
    const isNextStepDisabled =
        selectedProduct === 'constant' ? !selectedDuration || !selectedPowerSource : !selectedPowerSource;

    const handlePowerSourceSelect = (powerSource: PowerSourceType) => {
        setSelectedPowerSource(powerSource);
    };

    const handleDurationSelect = (duration: ContractDurationType) => {
        setSelectedDuration(duration);
    };

    return {
        calculatedOffer,
        contract,
        selectedProduct,
        powerSourceOptions,
        isNextStepDisabled,
        selectedDuration,
        selectedPowerSource,
        contractDurationOptions: getContractDurationConfigurations(),
        handlePowerSourceSelect,
        handleDurationSelect,
    };
};

export default useConfiguration;
