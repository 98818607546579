import { Box, Button, Stack, Typography } from '@mui/material';
import { ShareIcon } from 'assets/icons';
import TwoByTwo from 'components/layouts/TwoByTwoLayout';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import TimerBanner from 'components/ui/TimerBanner';
import { useTranslation } from 'react-i18next';

interface ConfigurationIntroSectionProps {
    powerSource?: string;
    location?: string;
}

const ConfigurationIntroSection = ({ powerSource, location }: ConfigurationIntroSectionProps) => {
    const { t: translate } = useTranslation('configuration');
    const { t: commonT } = useTranslation('common');
    const topLeftContent = (
        <Box>
            <SectionBasicInfo name={`${powerSource}, ${location}`} title={translate('TITLE')} />
        </Box>
    );
    const topRightContent = (
        <Button sx={{ display: 'flex', gap: 2, width: 'fit-content' }} variant="text">
            <ShareIcon />
            {commonT('SHARE_OFFER')}
        </Button>
    );
    const bottomLeftContent = (
        <Stack maxWidth={'680px'} gap={1}>
            <Typography variant="bodyRegularEmphasized">{translate('SUBTITLE')}</Typography>
            <Typography variant="bodyRegular" color={(theme) => theme.palette.text.primary}>
                {translate('DESCRIPTION')}
            </Typography>
        </Stack>
    );
    const bottomRightContent = <TimerBanner timeLeft={59} validTill="15:14" />;

    return (
        <TwoByTwo
            topLeftContent={topLeftContent}
            topRightContent={topRightContent}
            bottomLeftContent={bottomLeftContent}
            bottomRightContent={bottomRightContent}
        />
    );
};

export default ConfigurationIntroSection;
