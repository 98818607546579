import { Box, Stack, Typography, styled } from '@mui/material';
import { DiscountPercentageIcon } from 'assets/icons';
import cuponImagefrom from 'assets/images/cupon-discount.png';
import { Trans, useTranslation } from 'react-i18next';

const ICON_SIZE = 64;

const CuponBackground = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${cuponImagefrom})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '157px',
    width: '100%',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
    },
}));

const CuponIcon = styled(DiscountPercentageIcon)({
    width: ICON_SIZE,
    height: ICON_SIZE,
    fill: 'transparent',
});

const CuponSection = () => {
    const { t } = useTranslation('configuration');

    return (
        <CuponBackground>
            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={2}
                justifyContent={'space-between'}
                alignItems={{ md: 'center', sm: 'flex-start' }}
            >
                <Stack>
                    <Typography variant="leadEmphasized">{t('CUPON_TITLE')}</Typography>
                    <Trans>
                        <Typography maxWidth={656}>{t('CUPON_DESCRIPTION')}</Typography>
                    </Trans>
                </Stack>
                <CuponIcon />
            </Stack>
        </CuponBackground>
    );
};

export default CuponSection;
