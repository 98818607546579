import useToggle from 'hooks/useToggle';
import useNewOfferFlowStore from 'store/newOfferFlow';

const usePowerConsumption = () => {
    const { contract } = useNewOfferFlowStore();
    const { toggle: toggleDialog, value: isDialogOpen } = useToggle();

    return { contract, isDialogOpen, toggleDialog };
};

export default usePowerConsumption;
