import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Fade, Menu, Typography, styled } from '@mui/material';
import { ProfileIcon } from 'assets/icons';
import { Route } from 'data/routes/navigation-routes';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useProfileMenu from 'view-controllers/ui/useProfileMenu.controller';

export const CustomMenuItem = styled(NavLink)(({ theme }) => ({
    display: 'block',
    width: '100%',
    background: 'transparent',
    padding: '12px 16px',
    color: '#303636',
    textDecoration: 'none',
    '&.active, &:hover': {
        background: theme.palette.primary.light,
    },
}));

interface ProfileMenuProps {
    profileRoutes: Route[];
}

const ProfileMenu = ({ profileRoutes }: ProfileMenuProps) => {
    const { anchorButton, email, isOpen, handleClick, handleClose } = useProfileMenu();
    const { t } = useTranslation('header');

    return (
        <>
            <Button
                id="menuButton"
                variant="text"
                aria-controls={'profileMenu'}
                aria-haspopup={'true'}
                aria-expanded={'true'}
                onClick={handleClick}
                disableTouchRipple
                startIcon={<ProfileIcon />}
                endIcon={<ExpandMoreIcon />}
            >
                <Typography color={'inherit'} noWrap component={'span'}>
                    {email}
                </Typography>
            </Button>
            <Menu
                id="profileMenu"
                open={isOpen}
                anchorEl={anchorButton}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'menuButton',
                }}
                TransitionComponent={Fade}
            >
                {profileRoutes.map(({ path, label }) => (
                    <CustomMenuItem key={label} to={path} onClick={handleClose}>
                        {t(label)}
                    </CustomMenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ProfileMenu;
