import { createTheme } from '@mui/material/styles';

import { palette } from './palette';

const gothamBookCommonStyle = {
    fontWeight: 400,
    fontFamily: 'GothamBook',
};

const theme = createTheme({
    borderRadius: {
        small: 8,
        medium: 16,
        large: 24,
        xl: 99,
    },
    palette: palette,
    typography: (palette) => ({
        allVariants: {
            fontFamily: ['GothamMedium', 'GothamBook'].join(','),
            color: palette.text.primary,
        },
    }),
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    height: '100%',
                    margin: 0,
                    padding: 0,
                },
                body: {
                    height: '100%',
                    margin: 0,
                    padding: 0,
                    background: 'white',
                    // background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(235, 235, 235, 0.2) 100%)',
                },
                '#root': {
                    height: '100%',
                    display: 'flex',
                    overflowX: 'hidden',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                fontWeight: 500,
                letterSpacing: '0em',

                variantMapping: {
                    titleBig: 'h1',
                    titleSmall: 'h1',
                    heading1: 'h1',
                    heading2: 'h2',
                    heading3: 'h3',
                    heading4: 'h4',
                    lead: 'h5',
                    leadEmphasized: 'h5',
                    bodyRegular: 'h6',
                    bodyRegularEmphasized: 'h6',
                    bodySmall: 'p',
                    bodySmallEmphasized: 'p',
                    caption: 'p',
                    captionEmphasized: 'p',
                },
            },
        },
        MuiContainer: {},
        MuiRadio: {
            defaultProps: {
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: 0,
                    marginRight: 8,
                    '& .MuiSvgIcon-root': {
                        width: 20,
                        height: 20,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    '& .MuiTypography-root': {
                        ...gothamBookCommonStyle,
                    },
                },
            },
        },

        MuiButton: {
            defaultProps: {
                color: 'primary',
                variant: 'contained',
                disableElevation: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    lineHeight: '28px',
                    borderRadius: theme.borderRadius.xl,
                    fontSize: theme.typography.pxToRem(18),
                    minHeight: theme.typography.pxToRem(48),
                    textTransform: 'none',
                    ':disabled': {
                        opacity: 0.6,
                    },
                    '& .MuiButton-startIcon > *:nth-of-type(1), & .MuiButton-endIcon > *:nth-of-type(1)': {
                        fontSize: theme.typography.pxToRem(24),
                    },
                    [theme.breakpoints.down('md')]: {
                        padding: theme.spacing(2),
                        fontSize: theme.typography.pxToRem(16),
                        '& .MuiButton-startIcon > *:nth-of-type(1), & .MuiButton-endIcon > *:nth-of-type(1)': {
                            fontSize: theme.typography.pxToRem(16),
                        },
                    },
                }),
                sizeSmall: ({ theme }) => ({
                    lineHeight: '25.6px',
                    padding: theme.spacing(2),
                    fontSize: theme.typography.pxToRem(16),
                    [theme.breakpoints.down('md')]: {
                        fontSize: theme.typography.pxToRem(14),
                    },
                }),
                sizeMedium: ({ theme }) => ({
                    fontSize: theme.typography.pxToRem(18),
                    padding: theme.spacing(2, 2.5),
                    [theme.breakpoints.down('md')]: {
                        fontSize: theme.typography.pxToRem(16),
                        padding: theme.spacing(1.8, 2),
                    },
                }),
                containedPrimary: ({ theme }) => ({
                    color: theme.palette.primary.contrastText,
                    backgroundImage: theme.palette.background.gradient,
                    ':hover': {
                        background: theme.palette.primary.dark,
                        boxShadow: 'none',
                    },
                    ':active': {
                        background: theme.palette.background.primaryActive,
                    },
                    ':disabled': {
                        color: theme.palette.primary.contrastText,
                    },
                }),
                outlinedPrimary: ({ theme }) => ({
                    position: 'relative',
                    color: theme.palette.primary.main,
                    ':hover': {
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.primary.lighter,
                    },
                    ':active': {
                        color: theme.palette.primary.dark,
                        background: theme.palette.primary.light,
                        '&:after': {
                            // eslint-disable-next-line quotes
                            content: "''",
                            position: 'absolute',
                            left: -3,
                            top: -3,
                            bottom: -3,
                            right: -3,
                            border: `2px solid ${theme.palette.primary.lighter}`,
                            borderRadius: theme.borderRadius.xl,
                        },
                    },
                }),
                textPrimary: {
                    ':hover': {
                        backgroundColor: 'unset',
                    },
                },
                textSecondary: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    ':hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                }),
            },
            variants: [
                {
                    props: { variant: 'outlinedSelect' },
                    style: ({ theme }) => ({
                        fontSize: theme.typography.pxToRem(16),
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                        border: '1px solid #8D968D',
                        justifyContent: 'space-between',
                        borderRadius: theme.borderRadius.small,
                        padding: theme.spacing(0.9, 2),
                        whiteSpace: 'nowrap',
                        ':hover': {
                            backgroundColor: 'none',
                            background: 'none',
                        },
                        ':active': {
                            backgroundColor: 'none',
                        },
                        '& .MuiButton-startIcon > *:nth-of-type(1), & .MuiButton-endIcon > *:nth-of-type(1)': {
                            fontSize: theme.typography.pxToRem(18),
                        },
                        [theme.breakpoints.down('lg')]: {
                            fontSize: theme.typography.pxToRem(14),
                            padding: theme.spacing(0.9, 2),

                            '& .MuiButton-startIcon > *:nth-of-type(1), & .MuiButton-endIcon > *:nth-of-type(1)': {
                                fontSize: theme.typography.pxToRem(14),
                            },
                        },
                    }),
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    position: 'relative',
                    lineHeight: '25.6px',
                    fontWeight: 400,
                    fontFamily: 'GothamBook',
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.pxToRem(16),
                    [theme.breakpoints.down('md')]: {
                        fontSize: theme.typography.pxToRem(14),
                    },
                    '& > span': {
                        display: 'inline-block',
                        paddingBottom: theme.spacing(1),
                    },
                    ':disabled': {
                        opacity: 0.6,
                    },
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: ({ theme }) => ({
                    zIndex: 0,
                    width: 'fit-content',
                    '&.MuiFormControl-fullWidth': {
                        width: '-webkit-fill-available',
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '-webkit-fill-available',
                    },
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    // hack to avoid double style on MuidatePicker
                    '& .MuiInputBase-root': {
                        '& .MuiInputBase-input, input': {
                            border: '1px solid #8D968D',
                            borderRadius: theme.borderRadius.small,
                            padding: theme.spacing(1, 2),
                            '&.Mui-error': {
                                border: `1px solid ${theme.palette.error.main}`,
                            },
                        },
                    },
                }),
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    display: 'flex',
                    border: '2px solid transparent',
                    borderRadius: 10,
                    fontSize: theme.typography.pxToRem(16),
                    ':hover': {
                        border: `2px solid ${theme.palette.primary.lighter}`,
                    },
                    '&.Mui-focused': {
                        background: theme.palette.primary.light,
                        border: `2px solid ${theme.palette.primary.lighter}`,
                    },
                    '&.Mui-disabled': {
                        opacity: 0.6,
                        border: '2px solid transparent',
                    },
                    '&.Mui-error': {
                        borderColor: 'transparent',
                        '& .MuiInputBase-input': {
                            border: `1px solid ${theme.palette.error.main}`,
                        },
                    },
                    // DatePicker input
                    '&.MuiOutlinedInput-root': {
                        ':hover': {
                            // border: `2px solid ${theme.palette.primary.lighter}`,
                            // borderRadius: theme.borderRadius.small,
                            border: '2px solid transparent',
                            borderRadius: theme.borderRadius.small,
                        },
                        '&.Mui-focused': {
                            background: 'transparent',
                            border: '2px solid transparent',
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0,
                            fontSize: theme.typography.pxToRem(18),
                            color: theme.palette.text.primary,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            // border: '1px solid #8D968D',
                            // borderRadius: theme.borderRadius.small,
                            border: 'none',
                        },
                        '&.Mui-error': {
                            borderBottom: `1px solid ${theme.palette.error.main}`,
                        },

                        '& .MuiIconButton-edgeStart': {
                            '& .MuiTypography-root': {
                                color: theme.palette.text.primary,
                                fontSize: '1.2rem',
                                fontWeight: 500,
                            },
                            ':hover': {
                                background: 'transparent',
                            },
                        },
                    },
                    '&.MuiOutlinedInput-root.Mui-error .MuiInputBase-input': {
                        border: 'none',
                    },
                    [theme.breakpoints.down('md')]: {
                        lineHeight: 'unset',
                    },
                }),
                inputAdornedEnd: ({ theme }) => ({
                    padding: theme.spacing(1.5, 6, 1.5, 2),
                }),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    fontFamily: 'GothamBook',
                    fontSize: theme.typography.pxToRem(12),
                    margin: theme.spacing(1, 0.5),
                    '& .MuiSvgIcon-root': {
                        marginRight: theme.spacing(0.5),
                        fontSize: theme.typography.pxToRem(12),
                    },
                }),
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.primary,
                    '& .MuiSvgIcon-root': {
                        color: 'inherit',
                        fontSize: theme.typography.pxToRem(16),
                        [theme.breakpoints.down('md')]: {
                            fontSize: theme.typography.pxToRem(12),
                        },
                    },
                }),
                positionEnd: ({ theme }) => ({
                    position: 'absolute',
                    right: theme.spacing(2),
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: ({ theme }) => ({
                    '&&': {
                        minHeight: 'unset',
                        borderRadius: theme.borderRadius.small,
                        minWidth: theme.typography.pxToRem(200),
                        paddingRight: theme.spacing(6),
                        [theme.breakpoints.down('md')]: {
                            paddingRight: theme.spacing(4),
                        },
                        '& .MuiTypography-root': {
                            margin: 0,
                            fontWeight: 400,
                        },
                    },
                }),
                icon: ({ theme }) => ({
                    color: 'inherit',
                    padding: theme.spacing(0.75),
                    right: theme.spacing(1.25),
                    [theme.breakpoints.down('md')]: {
                        padding: theme.spacing(1),
                    },
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: ({ theme }) => ({
                    marginTop: theme.spacing(1),
                    borderRadius: theme.borderRadius.small,
                    '& .MuiMenuItem-root': {
                        padding: theme.spacing(1, 2),
                        ':hover, &.Mui-selected': {
                            background: theme.palette.primary.light,
                        },
                        '& .MuiTypography-root': {
                            margin: 0,
                            fontWeight: 400,
                        },
                    },

                    // DatePicker
                    '& .MuiPickersLayout-root': {
                        '& .MuiPickersLayout-contentWrapper': {
                            '& .MuiDateCalendar-root': {
                                //header
                                padding: '16px 24px',
                                width: 'auto',

                                color: theme.palette.text.primary,
                                [theme.breakpoints.down('md')]: {
                                    padding: 0,
                                    width: '320px',
                                },
                                '& .MuiPickersCalendarHeader-root': {
                                    fontWeight: 500,
                                    '& .MuiPickersCalendarHeader-labelContainer': {},

                                    '& .MuiPickersArrowSwitcher-root': {
                                        '& .MuiPickersArrowSwitcher-button': {
                                            color: theme.palette.text.primary,
                                            '&.Mui-disabled': {
                                                color: theme.palette.text.disabled,
                                            },
                                        },
                                    },
                                },

                                '& .MuiDayCalendar-root': {
                                    '& .MuiDayCalendar-header': {
                                        '& .MuiDayCalendar-weekDayLabel ': {
                                            color: theme.palette.text.primary,
                                            fontWeight: 700,
                                        },
                                    },
                                    '& .MuiDayCalendar-monthContainer': {
                                        '& .MuiDayCalendar-weekContainer': {
                                            '& .MuiPickersDay-root': {
                                                color: theme.palette.text.primary,
                                                '&.MuiPickersDay-today': {
                                                    border: 'none',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.9rem',
                                                    color: theme.palette.primary.main,
                                                    background: theme.palette.common.white,
                                                },
                                                '&.Mui-selected, :hover': {
                                                    fontWeight: 700,
                                                    color: theme.palette.common.white,
                                                    background: theme.palette.primary.main,
                                                },
                                                '&.Mui-disabled': {
                                                    color: theme.palette.text.disabled,
                                                },
                                            },
                                        },
                                    },
                                },
                                //month
                                '& .MuiMonthCalendar-root': {
                                    width: '342px',
                                    alignContent: 'flex-start',
                                    [theme.breakpoints.down('md')]: {
                                        width: '320px',
                                        paddingInline: '16px',
                                    },
                                    '& .MuiPickersMonth-root': {
                                        '& .MuiPickersMonth-monthButton': {
                                            color: theme.palette.primary.main,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            height: '48px',
                                            width: '96px',
                                            borderRadius: theme.borderRadius.xl,
                                            // background: 'beige',
                                            [theme.breakpoints.down('md')]: {
                                                width: '100%',
                                                marginInline: '4px',
                                            },

                                            '&.Mui-selected, :hover': {
                                                color: theme.palette.common.white,
                                                background: theme.palette.primary.main,
                                            },
                                            '&.Mui-disabled': {
                                                color: theme.palette.text.disabled,
                                            },
                                        },
                                    },
                                },
                                //year
                                '& .MuiYearCalendar-root': {
                                    width: '342px',
                                    alignContent: 'flex-start',
                                    [theme.breakpoints.down('md')]: {
                                        width: '320px',
                                        paddingInline: '16px',
                                    },
                                    '& .MuiPickersYear-root': {
                                        '& .MuiPickersYear-yearButton': {
                                            color: theme.palette.primary.main,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            height: '48px',
                                            width: '96px',
                                            borderRadius: theme.borderRadius.xl,
                                            [theme.breakpoints.down('md')]: {
                                                width: '100%',
                                                marginInline: '4px',
                                            },

                                            '&.Mui-selected, :hover': {
                                                color: theme.palette.common.white,
                                                background: theme.palette.primary.main,
                                            },
                                            '&.Mui-disabled': {
                                                color: theme.palette.text.disabled,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                }),
            },
        },
        MuiMenu: {
            variants: [
                {
                    props: {
                        id: 'profileMenu',
                    },
                    style: ({ theme }) => ({
                        '.MuiPaper-root': {
                            minWidth: '20%',
                        },
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                        [theme.breakpoints.up('md')]: {
                            display: 'flex',
                        },
                    }),
                },
            ],
        },
        MuiDrawer: {
            variants: [
                {
                    props: {
                        id: 'mobileNavDrawer',
                    },
                    style: ({ theme }) => ({
                        '.MuiPaper-root': { height: '100%', top: '60px', p: 2 },
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            zIndex: 1,
                        },
                        [theme.breakpoints.up('md')]: {
                            display: 'none',
                        },
                    }),
                },
            ],
        },
    },
});

theme.typography = {
    ...theme.typography,
    titleBig: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(72),
        lineHeight: theme.typography.pxToRem(86.4),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(48),
            lineHeight: theme.typography.pxToRem(57.6),
        },
    },
    titleSmall: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(56),
        lineHeight: theme.typography.pxToRem(67.2),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(42),
            lineHeight: theme.typography.pxToRem(50.4),
        },
    },
    heading1: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(48),
        lineHeight: theme.typography.pxToRem(57.6),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(38.4),
        },
    },
    heading2: {
        fontSize: theme.typography.pxToRem(36),
        lineHeight: theme.typography.pxToRem(46.8),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(26),
            lineHeight: theme.typography.pxToRem(33.8),
        },
    },
    heading3: {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36.4),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(22),
            lineHeight: theme.typography.pxToRem(28.6),
        },
    },
    heading4: {
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(31.2),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(20),
            lineHeight: theme.typography.pxToRem(26),
        },
    },
    lead: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(35.2),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(28.8),
        },
    },
    leadEmphasized: {
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(35.2),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(28.8),
        },
    },
    bodyRegular: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28.8),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(25.6),
        },
    },
    bodyRegularEmphasized: {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28.8),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(25.6),
        },
    },
    bodySmall: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(25.6),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(21),
        },
    },
    bodySmallEmphasized: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(25.6),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(21),
        },
    },
    caption: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(19.6),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(16.8),
        },
    },
    captionEmphasized: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(19.6),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(16.8),
        },
    },
    captionSmall: {
        ...gothamBookCommonStyle,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16.8),
    },
};

export default theme;
