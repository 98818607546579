import DCPStepper from 'components/ui/DCPStepper/DCPStepper';
import { getExistingCustomerNewOfferSteps } from 'data/configs/existing-customer-new-offer';
import { useTranslation } from 'react-i18next';
import NewOfferRouter from 'routers/NewOfferRouter';
import Container from 'styles/styled-components/Container';
import useNewOffer from 'view-controllers/pages/NewOffer/useNewOffer.controller';

const NewOffer = () => {
    const { currentStep } = useNewOffer();
    useTranslation('new-offer');

    return (
        <Container size="lg" sx={{ my: 6 }}>
            <DCPStepper steps={getExistingCustomerNewOfferSteps()} activeStep={currentStep} />
            <NewOfferRouter />
        </Container>
    );
};

export default NewOffer;
