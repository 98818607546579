import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type User = {
    no: string;
    companyInvoiceEmail: string;
    companyName?: string;
    address: {
        street: string;
        zip: string;
        city: string;
    };
    contact: { fullName: string; phoneNumber: string; email: string };
};

interface AuthStore {
    token: string | null;
    setToken: (token: string) => void;
    clearToken: () => void;
    user: User | null;
    setUser: (user: User) => void;
}

const useAuthStore = create<AuthStore>()(
    persist(
        (set) => ({
            token: null,
            setToken: (token) => set({ token }),
            clearToken: () => set({ token: null }),
            user: null,
            setUser: (user) => set({ user }),
        }),

        { name: 'auth-store' },
    ),
);

export default useAuthStore;
