// ImageWrapper.tsx
import { styled } from '@mui/system';
import React from 'react';
import useIgnorePadding from 'view-controllers/ui/useIgnorePadding.controller';

const StyledImageWrapper = styled('div')<{ marginLeft: string; marginRight: string; marginBottom: string }>(
    ({ marginLeft, marginRight, marginBottom }) => ({
        position: 'relative',
        marginLeft,
        marginRight,
        marginBottom,
    }),
);

interface ImageWrapperProps {
    children: React.ReactNode;
    ignoreBottom?: boolean;
}

const IgnorePadding = ({ children, ignoreBottom }: ImageWrapperProps) => {
    const { marginLeft, marginRight, marginBottom, wrapperRef } = useIgnorePadding({ ignoreBottom });

    return (
        <StyledImageWrapper
            ref={wrapperRef}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginBottom={marginBottom}
        >
            {children}
        </StyledImageWrapper>
    );
};

export default IgnorePadding;
