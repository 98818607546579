import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { AdditionIcon, ArrowDownIcon, InfoIcon, MultiplicationIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { borderPalette, chartPallete } from 'styles/theme/palette';

interface SpotCompositionCostSectionProps {
    riskPremium?: number;
    fullCoverage?: number;
}

const CalculationBox = styled(Box)({
    borderRadius: '8px',
    padding: '24px',
    border: `2px solid ${borderPalette.highlight}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const InnerVariableBox = styled(Box)({
    background: chartPallete.area.area,
    borderRadius: '8px',
    padding: '24px',
    border: `2px solid ${borderPalette.highlight}`,
    maxHeight: '155px',
});

const PERCENT_SIGN = '%';
const RP_PER_KWH = 'Rp / kWh';
const INFO_ICON_SIZE = '20px';
const MULTIPLICATION_ICON_SIZE = '20px';
const ADDITION_ICON_SIZE = '15px';
const ARROW_DOWN_ICON_SIZE_WIDTH = '17.41px';
const ARROW_DOWN_ICON_SIZE_HEIGHT = '9.71px';

const ConfigInfoIcon = styled(InfoIcon)({
    width: INFO_ICON_SIZE,
    height: INFO_ICON_SIZE,
});

const ConfigMultiplicationIcon = styled(MultiplicationIcon)({
    width: MULTIPLICATION_ICON_SIZE,
    height: MULTIPLICATION_ICON_SIZE,
});

const ConfigAdditionIcon = styled(AdditionIcon)({
    width: ADDITION_ICON_SIZE,
    height: ADDITION_ICON_SIZE,
});

const ConfigArrowDownIcon = styled(ArrowDownIcon)({
    width: ARROW_DOWN_ICON_SIZE_WIDTH,
    height: ARROW_DOWN_ICON_SIZE_HEIGHT,
});

const SpotCompositionCostSection = ({ riskPremium, fullCoverage }: SpotCompositionCostSectionProps) => {
    const { t } = useTranslation('configuration');

    return (
        <Stack flexGrow={1} gap={5} alignItems={'flex-start'}>
            <Typography variant="bodyRegularEmphasized">{t('SPOT_COMPOSITION_COST_TITLE')}</Typography>
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={2}
            >
                <CalculationBox maxWidth={'210px'}>
                    <Typography variant="leadEmphasized">{t('ELECTRICITY_PURCHASE_PER_MONTH')}</Typography>
                </CalculationBox>
                <ConfigMultiplicationIcon />
                <CalculationBox>
                    <Typography variant="leadEmphasized">{t('ELECTRICITY_PURCHASE_PRICE_TITLE')}</Typography>
                    <Stack
                        direction={{ sm: 'column', md: 'row' }}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        gap={2}
                    >
                        <InnerVariableBox>
                            <Typography variant="bodyRegularEmphasized">
                                {t('ELECTRICITY_PURCHASE_SPOT_PRICE_TITLE')}
                            </Typography>
                            <Typography variant="bodySmallEmphasized">{t('VARIABLE_HOURLY')}</Typography>
                            <Typography color={(theme) => theme.palette.text.secondary} variant="caption">
                                {t('VIEWED_ON_EPEX')}
                            </Typography>
                        </InnerVariableBox>
                        <ConfigAdditionIcon />
                        <InnerVariableBox flexGrow={1}>
                            <Typography variant="bodyRegularEmphasized">{t('SURCHARGE')}</Typography>
                            <Typography display={'flex'} gap={1} variant="bodySmall">
                                {t('RISK_PREMIUM')}
                                <Typography>
                                    {riskPremium}
                                    {PERCENT_SIGN}
                                </Typography>
                                <ConfigInfoIcon />
                            </Typography>
                            <Typography flexWrap={'wrap'} display={'flex'} gap={1} variant="bodySmall">
                                {t('FULL_COVERAGE_GUARANTEE')}
                                <Typography>
                                    {fullCoverage} {RP_PER_KWH}
                                </Typography>
                                <ConfigInfoIcon />
                            </Typography>
                        </InnerVariableBox>
                    </Stack>
                </CalculationBox>
            </Stack>
            <Button sx={{ display: 'flex', gap: 1 }} variant={'text'} color={'primary'}>
                {t('EXAMPLE_CALCULATION_BUTTON')} <ConfigArrowDownIcon />
            </Button>
        </Stack>
    );
};

export default SpotCompositionCostSection;
