export const switchComponentBackground = '#EFF0EF';

export const skeletonPalette = {
    border: '#D5D8D5',
    fill: '#BFC4BF',
};

export const comparisonPalette = {
    forecast: {
        stroke: '#B161A280',
        past: '#B161A2',
        estimated: '#B161A233',
    },
    consumption: {
        fill: '#972784',
    },
};

export const chartPallete = {
    area: {
        axisColor: '#515151',
        stroke: '#6C9C30',
        area: '#FAFDE2',
        gridStroke: '#DBDFDB',
    },
    bar: {
        forecast: {
            stroke: '#6c9c3080',
            fill: '#6c9c3033',
        },
        highRate: {
            fill: '#6C9C30',
        },
        lowRate: {
            fill: '#C8DD2D',
        },
        averageConsumption: {
            stroke: '#F2E7F0',
            fill: '#9F358B',
        },
    },
};

export const palette = {
    primary: {
        main: '#365F10',
        dark: '#21420B',
        light: '#F1F9E8',
        lighter: '#C8E6A4',
    },
    text: {
        primary: '#232623',
        secondary: '#4F544F',
        disabled: '#72757B',
    },
    error: {
        main: '#D73D19',
    },
    background: {
        default: '#F4F4F4',
        gradient: 'linear-gradient(256.56deg, rgba(154, 178, 15, 1) -22.21%, rgba(78, 120, 24, 1) 65.58%)',
        primaryActive: '#53821F',
        paper: '#FFF',
        'neuatral-medium': '#EFF0EF',
    },

    gradients: {
        primary: 'linear-gradient(270deg, #EFF0EF 2.77%, #D5E45E 55.49%, #6C9C30 90%)',
    },

    illustration: {
        'illustration-400': '#A7B805',
    },

    common: {
        white: '#FFF',
    },
};

export const borderPalette = {
    highlight: '#DBE873',
};

export const cardPalette = {
    border: '#D5D8D5',
    stackBgColor: '#FAFAFA',
};

export const graphLegendPaletter = {
    green: '#86BC46',
    'chart-violet-dark': '#B161A2',
};

export const iconTextBannerPalette = {
    primary: {
        background: '#E4F3D3',
        border: palette.primary.main,
    },
    neutral: {
        background: '#FAFAFA',
        border: '#D5D8D5',
    },
};
