import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CheckIcon } from 'assets/icons';
import { palette, switchComponentBackground } from 'styles/theme/palette';
import StepLabel from './StepLabel';

interface StepProps {
    step: string;
    index: number;
    isActive: boolean;
    isCompleted: boolean;
    isLastStep: boolean;
}

const StepRingWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active }) => ({
    borderRadius: '50%',
    border: active ? `2px solid ${palette.primary.main}` : 'none',
    padding: '2px',
}));

const StepBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 32,
    height: 32,
    flexShrink: 1,
    position: 'relative',
}));

const CHECK_ICON_WIDTH = '13.42px';
const CHECK_ICON_HEIGHT = '9.34px';

const StepCheckIcon = styled(CheckIcon)({
    width: CHECK_ICON_WIDTH,
    height: CHECK_ICON_HEIGHT,
});

const Step = ({ step, index, isActive, isCompleted, isLastStep }: StepProps) => (
    <StepRingWrapper active={isActive}>
        <StepBox bgcolor={isCompleted || isActive ? palette.primary.main : switchComponentBackground}>
            <Typography
                color={!isCompleted && !isActive ? palette.text.primary : palette.common.white}
                variant="captionEmphasized"
            >
                {isCompleted ? <StepCheckIcon data-testid="check-icon" /> : index + 1}
            </Typography>
            <StepLabel index={index} isLastStep={isLastStep}>
                {step}
            </StepLabel>
        </StepBox>
    </StepRingWrapper>
);

export default Step;
