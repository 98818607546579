import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';

interface IDtpModalProps extends DialogProps {
    title: string;
    onClose: () => void;
}

const DCPModal = ({ open, onClose, title, children, sx, maxWidth }: IDtpModalProps) => {
    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    padding: '24px 32px',
                },
                ...sx,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ m: 0 }} variant="heading3">
                    {title}
                </Typography>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Box>{children}</Box>
        </Dialog>
    );
};

export default DCPModal;
