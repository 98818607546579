import oneYearImage from 'assets/images/one-year.png';
import threeYearsLinerImage from 'assets/images/tree-year-linear.png';
import threeYearsDownTrendImage from 'assets/images/tree-years-down-trend.png';
import nuclearPng from 'assets/images/nuclear.png';
import waterEuPng from 'assets/images/water-eu.png';
import waterChPng from 'assets/images/water-ch.png';
import photovoltaikPng from 'assets/images/photovoltaik.png';

import i18n from 'i18n';

export type ContractDurationType = 'one-year' | 'three-year-uniform' | 'three-year-annual';

export type pricePerKwhAnnual = {
    period: string;
    pricePerKwh: number;
};

export type ContractDurationOption = {
    type: ContractDurationType;
    title: string;
    image: string;
    pricePerKwh: number;
    pricePerYear: number;
    annualPricePerKwh?: pricePerKwhAnnual[];
    isActive?: boolean;
    showInfoIcon?: boolean;
};

export const getContractDurationConfigurations = (): ContractDurationOption[] => [
    {
        type: 'one-year',
        title: i18n.t('configuration:ONE_YEAR_CONTRACT_TITLE'),
        image: oneYearImage,
        pricePerKwh: 24.21,
        pricePerYear: 9900,
    },
    {
        type: 'three-year-uniform',
        title: i18n.t('configuration:THREE_YEARS_CONTRACT_CONST_TITLE'),
        image: threeYearsLinerImage,
        pricePerKwh: 22.13,
        pricePerYear: 9511,
        showInfoIcon: true,
    },
    {
        type: 'three-year-annual',
        title: i18n.t('configuration:THREE_YEARS_CONTRACT_VAR_TITLE'),
        image: threeYearsDownTrendImage,
        pricePerKwh: 21.86,
        pricePerYear: 9466,
        annualPricePerKwh: [
            {
                period: '01.01.2025 - 31.12.2025',
                pricePerKwh: 24.21,
            },
            {
                period: '01.01.2026 - 31.12.2026',
                pricePerKwh: 22.02,
            },
            {
                period: '01.01.2027 - 31.12.2027',
                pricePerKwh: 19.35,
            },
        ],
        isActive: true,
        showInfoIcon: true,
    },
];

export type PowerSourceType = 'nuclear' | 'water-eu' | 'water-ch' | 'photovoltaik';

export type PowerSourceValue = {
    pricePerKwh: string | number;
    pricePerYear: string | number;
};

export type PowerSourceOption = {
    type: PowerSourceType;
    title: string;
    image: string;
    pricePerKwh: number | string;
    pricePerYear: number | string;
    origin: string;
    attribute: string;
    isActive?: boolean;
};

export type PowerSourceMap = Record<PowerSourceType, PowerSourceValue>;

export const getPowerSourceConfigurations = (consumptionValues: PowerSourceMap): PowerSourceOption[] => {
    return [
        {
            type: 'nuclear',
            title: i18n.t('configuration:POWER_SOURCE_PLANT'),
            origin: i18n.t('configuration:POWER_SOURCE_CARD_ORIGIN_CH'),
            attribute: i18n.t('configuration:POWER_SOURCE_ATTRIBUTE_CHEAPEST'),
            image: nuclearPng,
            pricePerKwh: consumptionValues.nuclear.pricePerKwh,
            pricePerYear: consumptionValues.nuclear.pricePerYear,
        },
        {
            type: 'water-eu',
            title: i18n.t('configuration:POWER_SOURCE_WATER_EU'),
            origin: i18n.t('configuration:POWER_SOURCE_CARD_ORIGIN_EU'),
            attribute: i18n.t('configuration:POWER_SOURCE_ATTRIBUTE_LOW_CO2'),
            image: waterEuPng,
            pricePerKwh: consumptionValues['water-eu'].pricePerKwh,
            pricePerYear: consumptionValues['water-eu'].pricePerYear,
            isActive: true,
        },
        {
            type: 'water-ch',
            title: i18n.t('configuration:POWER_SOURCE_WATER_CH'),
            origin: i18n.t('configuration:POWER_SOURCE_CARD_ORIGIN_CH'),
            attribute: i18n.t('configuration:POWER_SOURCE_ATTRIBUTE_LOW_CO2'),
            image: waterChPng,
            pricePerKwh: consumptionValues['water-ch'].pricePerKwh,
            pricePerYear: consumptionValues['water-ch'].pricePerYear,
        },
        {
            type: 'photovoltaik',
            title: i18n.t('configuration:POWER_SOURCE_SOLAR_PANEL'),
            origin: i18n.t('configuration:POWER_SOURCE_CARD_ORIGIN_CH'),
            attribute: i18n.t('configuration:POWER_SOURCE_ATTRIBUTE_CONTRIBUTION'),
            image: photovoltaikPng,
            pricePerKwh: consumptionValues.photovoltaik.pricePerKwh,
            pricePerYear: consumptionValues.photovoltaik.pricePerYear,
        },
    ];
};
