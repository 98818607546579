import newOfferRoutes from 'data/routes/new-offer-routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import useNewOfferRouter from 'view-controllers/router/useNewOfferRouter.controller';

const NewOfferRouter = () => {
    const { redirectContractId } = useNewOfferRouter();

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Navigate to={`/customer/contract/${redirectContractId}/new-offer/power-consumption`} replace />
                }
            />
            {newOfferRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={route.component} />
            ))}
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
};

export default NewOfferRouter;
