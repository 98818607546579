import React, { ReactNode } from 'react';
import { Stack, StackProps } from '@mui/material';

interface TwoByTwoProps extends StackProps {
    topLeftContent: ReactNode;
    topRightContent: ReactNode;
    bottomLeftContent: ReactNode;
    bottomRightContent: ReactNode;
}

const TwoByTwo: React.FC<TwoByTwoProps> = ({
    topLeftContent,
    topRightContent,
    bottomLeftContent,
    bottomRightContent,
    ...rest
}) => {
    return (
        <Stack {...rest} gap={4}>
            <Stack
                gap={2}
                flexDirection={{ sm: 'column-reverse', md: 'row' }}
                justifyContent={'space-between'}
                alignItems={{ sm: 'flex-start', md: 'flex-end' }}
            >
                {topLeftContent}
                {topRightContent}
            </Stack>

            <Stack gap={2} flexDirection={{ md: 'row', sm: 'column' }} justifyContent={'space-between'}>
                {bottomLeftContent}
                {bottomRightContent}
            </Stack>
        </Stack>
    );
};

export default TwoByTwo;
