import { Box, Stack, styled } from '@mui/material';
import { InfoIcon } from 'assets/icons';

const ICON_SIZE = 26.67;

const InfoBannerIcon = styled(InfoIcon)({
    width: ICON_SIZE,
    height: ICON_SIZE,
});

const InfoBannerWrapper = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    border: `1px solid #A4D270`,
    padding: '24px',
    borderRadius: '24px',
    marginBottom: '32px',
}));

interface InfoBannerProps {
    children: React.ReactNode;
}

const InfoBanner = ({ children }: InfoBannerProps) => (
    <InfoBannerWrapper gap={3} flexDirection={{ md: 'row', sm: 'column' }}>
        <InfoBannerIcon />
        <Box>{children}</Box>
    </InfoBannerWrapper>
);

export default InfoBanner;
