import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';

interface LinkButtonProps extends ButtonProps {
    to: string;
}

const LinkButton = ({ to, disabled, ...rest }: LinkButtonProps) => (
    <Link to={disabled ? '#' : to}>
        <Button disabled={disabled} {...rest} />
    </Link>
);

export default LinkButton;
