import { Box, Typography } from '@mui/material';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'background.default',
                padding: 2,
            }}
        >
            <Typography variant="body2" color="text.secondary" align="center">
                © {year} CKW. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
