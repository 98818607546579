import { CardCheckedCheckboxIcon, CardEmptyCheckboxIcon } from 'assets/icons';
import DCPCheckbox, { DCPCheckboxProps } from './DCPCheckbox';
import { styled } from '@mui/material';

const CARD_CHECKBOX_ICON_SIZE = '35px';

const EmptyIcon = styled(CardEmptyCheckboxIcon)({
    width: CARD_CHECKBOX_ICON_SIZE,
    height: CARD_CHECKBOX_ICON_SIZE,
});

const CheckedIcon = styled(CardCheckedCheckboxIcon)({
    width: CARD_CHECKBOX_ICON_SIZE,
    height: CARD_CHECKBOX_ICON_SIZE,
});

interface CardCheckBoxProps extends DCPCheckboxProps {}

const CardCheckBox = ({ ...rest }: CardCheckBoxProps) => {
    return <DCPCheckbox sx={{ marginX: 'auto' }} {...rest} icon={<EmptyIcon />} checkedIcon={<CheckedIcon />} />;
};

export default CardCheckBox;
