import { Stack } from '@mui/material';
import LoadingChecks from 'components/pages/NewOffer/CalculationLoading/LoadingChecks';
import DCPProgressBar from 'components/ui/DCPProgressBar';
import { useTranslation } from 'react-i18next';
import Container from 'styles/styled-components/Container';
import useCalculationLoading from 'view-controllers/pages/NewOffer/useCalculationLoading.controller';

const CalculactionLoading = () => {
    const { t } = useTranslation('calculation-loading');
    const { calcStageList, progressPercentage, timeLeft, isFinished } = useCalculationLoading();

    return (
        <Container size="sm">
            <Stack flexGrow={1} justifyContent={'center'} gap={4}>
                <DCPProgressBar
                    title={t('TITLE')}
                    progress={progressPercentage}
                    timeLeftLabel={isFinished ? t('AlMOST_DONE') : t('TIME_LEFT', { time: timeLeft })}
                />
                <LoadingChecks list={calcStageList} />
            </Stack>
        </Container>
    );
};

export default CalculactionLoading;
