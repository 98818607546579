import { getInitCalculationLoadingStages } from 'data/configs/calculation-loading';
import { mockStateChecker } from 'data/mocks/mock-state-checker';
import useToggleNavigation from 'hooks/useToggleNavigation';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useCalculationLoading = () => {
    const navigate = useNavigate();
    const { contractId } = useParams();
    useToggleNavigation();

    const [calculationLoadingStages, setCalculationLoadingStages] = useState(getInitCalculationLoadingStages());

    const calcStageList = useMemo(() => Object.values(calculationLoadingStages), [calculationLoadingStages]);

    const progressPercentage = useMemo(() => {
        const finishedStagesCount = calcStageList.filter((stage) => stage.isFinished).length;
        return (finishedStagesCount / calcStageList.length) * 100 || 1;
    }, [calcStageList]);

    const timeLeft = useMemo(() => {
        return Math.ceil((100 - progressPercentage) / 25) * 10;
    }, [progressPercentage]);

    const isFinished = useMemo(() => progressPercentage === 100, [progressPercentage]);

    const handleLoadingStageChanged = (step: string) => {
        setCalculationLoadingStages((prev) => ({
            ...prev,
            [step]: { ...prev[step], isFinished: true },
        }));
    };

    useEffect(() => {
        mockStateChecker(handleLoadingStageChanged);
    });

    useEffect(() => {
        if (!isFinished) return;

        const timeout = setTimeout(() => {
            navigate(`/customer/contract/${contractId}/new-offer/product-selection`);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [isFinished, navigate, contractId]);

    return { timeLeft, progressPercentage, calcStageList, isFinished };
};

export default useCalculationLoading;
