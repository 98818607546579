import { useEffect, useRef, useState } from 'react';

interface UseIgnorePadding {
    ignoreBottom?: boolean;
}

const useIgnorePadding = ({ ignoreBottom }: UseIgnorePadding) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [marginLeft, setMarginLeft] = useState('0px');
    const [marginRight, setMarginRight] = useState('0px');
    const [marginBottom, setMarginBottom] = useState('0px');

    useEffect(() => {
        if (wrapperRef.current && wrapperRef.current.parentElement) {
            const style = window.getComputedStyle(wrapperRef.current.parentElement);
            setMarginLeft(`-${style.paddingLeft}`);
            setMarginRight(`-${style.paddingRight}`);
            if (ignoreBottom) {
                setMarginBottom(`-${style.paddingBottom}`);
            }
        }
    }, [ignoreBottom]);

    return { marginLeft, marginRight, wrapperRef, marginBottom };
};

export default useIgnorePadding;
