import LinkButton from 'components/ui/LinkButton';
import PageControls from 'components/ui/PageControls';
import { useTranslation } from 'react-i18next';

interface ProductSelectionPageControlsProps {
    contractUuid?: string;
    isNextDisabled?: boolean;
}

const ProductSelectionPageControls = ({ contractUuid, isNextDisabled }: ProductSelectionPageControlsProps) => {
    const { t: commonT } = useTranslation('common');

    return (
        <PageControls
            startControl={
                <LinkButton disabled to={`/customer/renew-contract/${contractUuid}`} variant="text">
                    {commonT('BACK')}
                </LinkButton>
            }
            endControl={
                <LinkButton
                    to={`/customer/contract/${contractUuid}/new-offer/configuration`}
                    variant="contained"
                    disabled={isNextDisabled}
                >
                    {commonT('NEXT')}
                </LinkButton>
            }
        />
    );
};

export default ProductSelectionPageControls;
