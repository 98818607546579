import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface OfferBasicInfoRowProps {
    label?: string;
    value?: ReactNode;
}

const OfferBasicInfoRow = ({ label, value }: OfferBasicInfoRowProps) => {
    return (
        <Stack gap={0.5}>
            <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                {label}
            </Typography>
            <Typography variant="bodyRegularEmphasized">{value}</Typography>
        </Stack>
    );
};

export default OfferBasicInfoRow;
