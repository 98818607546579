import { Stack, Typography } from '@mui/material';
import ContractDurationCard from 'components/ui/ContractDurationCard';
import SectionBasicInfo from 'components/ui/SectionBasicInfo';
import { ContractDurationOption, ContractDurationType } from 'data/configs/new-offer-configuration';

import { useTranslation } from 'react-i18next';

export interface ContractDurationSectionProps {
    contractDurationOptions: ContractDurationOption[];
    selectedDuration?: ContractDurationType | null;
    handleDurationSelect: (duration: ContractDurationType) => void;
}

const ContractDurationSection = ({
    contractDurationOptions,
    selectedDuration,
    handleDurationSelect,
}: ContractDurationSectionProps) => {
    const { t } = useTranslation('configuration');
    return (
        <Stack gap={5}>
            <Stack gap={1}>
                <SectionBasicInfo
                    size="sm"
                    title={t('OFFER_DURATION_TITLE')}
                    description={t('OFFER_DURATION_DESCRIPTION')}
                />
            </Stack>
            <Stack flexDirection={'row'} gap={4} flexWrap={'wrap'} justifyContent={'center'}>
                {contractDurationOptions.map((contractDurationOption) => (
                    <ContractDurationCard
                        key={contractDurationOption.type}
                        type={contractDurationOption.type}
                        title={contractDurationOption.title}
                        image={contractDurationOption.image}
                        pricePerKwh={contractDurationOption.pricePerKwh}
                        pricePerYear={contractDurationOption.pricePerYear}
                        isActive={contractDurationOption.isActive}
                        showInfoIcon={contractDurationOption.showInfoIcon}
                        onClick={handleDurationSelect}
                        isSelected={contractDurationOption.type === selectedDuration}
                    />
                ))}
            </Stack>
            <Typography variant={'caption'} color={'text.secondary'}>
                {t('SECTION_NOTE', { consumption: "124'654" })}
            </Typography>
        </Stack>
    );
};

export default ContractDurationSection;
