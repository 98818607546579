import i18n from 'i18n';
import { expiresIn } from './date-time-formaters';

export const contractExpiresIn = (endDateString?: string) => {
    const difference = expiresIn(endDateString);

    if (difference === undefined || difference < 0) {
        return '';
    }

    if (difference === 0) {
        return i18n.t('contract-card:CONTRACT_ENDS_TODAY');
    } else if (difference === 1) {
        return i18n.t('contract-card:CONTRACT_ENDS_TOMORROW');
    } else if (difference < 30) {
        return i18n.t('contract-card:CONTRACT_ENDS_IN_DAYS', { days: difference });
    } else if (difference < 365) {
        return i18n.t('contract-card:CONTRACT_ENDS_IN_MONTHS', { months: Math.floor(difference / 30) });
    } else {
        return i18n.t('contract-card:CONTRACT_ENDS_IN_YEARS', { years: Math.floor(difference / 365) });
    }
};
