import { Stack } from '@mui/material';
import DesktopNavItems from './DesktopNavItems';
import ProfileMenu from './ProfileMenu';
import { Route } from 'data/routes/navigation-routes';

interface DesktopMenuProps {
    navRoutes: Route[];
    profileRoutes: Route[];
}

const DesktopMenu = ({ navRoutes, profileRoutes }: DesktopMenuProps) => {
    return (
        <Stack
            flex={1}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            display={{ xs: 'none', md: 'flex' }}
        >
            <DesktopNavItems navRoutes={navRoutes} />
            <ProfileMenu profileRoutes={profileRoutes} />
        </Stack>
    );
};

export default DesktopMenu;
