import { ContractDurationType, PowerSourceType } from 'data/configs/new-offer-configuration';
import { Contract } from 'data/mocks/contracts-mock';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Offer {
    price: number;
    riskPremium: number;
    fullCoverage: number;
    no: string;
    revisionId: string;
    startDate?: string;
    endDate?: string;
}

export type ProductSelected = 'constant' | 'variable';
interface NewOfferFlowStore {
    // Existing contract that offer is being generated for
    contract: Contract | null;
    setContract: (contract: Contract) => void;
    // Offer generated in process
    calculatedOffer: Offer | null;
    setCalculatedOffer: (offer: Offer) => void;
    // User selected Configuration
    selectedProduct: ProductSelected | null;
    setSelectedProduct: (product: ProductSelected) => void;
    selectedDuration: ContractDurationType | null;
    setSelectedDuration: (duration: ContractDurationType) => void;
    selectedPowerSource: PowerSourceType | null;
    setSelectedPowerSource: (powerSource: PowerSourceType) => void;
}

const useNewOfferFlowStore = create<NewOfferFlowStore>()(
    persist(
        (set) => ({
            calculatedOffer: {
                price: 21.86,
                riskPremium: 2,
                fullCoverage: 1.94,
                no: '124383920',
                revisionId: '0',
                startDate: '2024-10-01',
                endDate: '2025-10-01',
            },
            selectedProduct: null,
            setCalculatedOffer: (offer) => set({ calculatedOffer: offer }),
            contract: null,
            setContract: (contract) => set({ contract }),
            setSelectedProduct: (product) => set({ selectedProduct: product }),
            selectedDuration: null,
            setSelectedDuration: (duration) => set({ selectedDuration: duration }),
            selectedPowerSource: null,
            setSelectedPowerSource: (powerSource) => set({ selectedPowerSource: powerSource }),
        }),

        { name: 'new-offer-flow' },
    ),
);

export default useNewOfferFlowStore;
