import { SvgIconProps, keyframes, styled } from '@mui/material';
import { LoaderIcon } from 'assets/icons';

interface DCPLoaderSpinnerProps extends SvgIconProps {}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled('div')(() => ({
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    animation: `${spin} 1s linear infinite`,
    transformOrigin: 'center', // adjust these values as needed
}));

const SpinningLoaderIcon = styled(LoaderIcon)(() => ({
    width: '10px',
    height: '20px',
}));

const DCPLoaderSpinner = (props: DCPLoaderSpinnerProps) => {
    return (
        <LoaderWrapper>
            <SpinningLoaderIcon {...props} />
        </LoaderWrapper>
    );
};

export default DCPLoaderSpinner;
