import { Stack, Typography } from '@mui/material';
import { Contract } from 'data/mocks/contracts-mock';
import ContractCard from '../../ui/ContractCard/ContractCard';

export interface ContractListingProps {
    contracts: Contract[];
    title: string;
    contractStatus?: 'active' | 'terminated';
}

const ContractListing = ({ contracts, title }: ContractListingProps) => {
    return (
        <Stack mb={8} rowGap={3} mt={6}>
            <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', md: '24px' }, my: 0 }} variant="h4">
                {title}
            </Typography>
            <Stack direction={'row'} gap={3} justifyContent="start" flexWrap="wrap">
                {contracts.map((contract) => (
                    <ContractCard key={contract.uuid} contract={contract} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ContractListing;
