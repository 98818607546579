import { Checkbox, CheckboxProps, Stack, SxProps, Theme, Typography } from '@mui/material';
import Show from 'components/wrappers/Show';
import { palette } from 'styles/theme/palette';

export interface DCPCheckboxProps extends CheckboxProps {
    label?: string | React.ReactNode;
    sx?: SxProps<Theme>;
}

const DCPCheckbox = ({ label, sx, ...rest }: DCPCheckboxProps) => {
    return (
        <Stack sx={sx} flexDirection={'row'} alignItems={'center'} gap={0.5}>
            <Checkbox
                {...rest}
                sx={{
                    padding: 'unset',
                    color: palette.text.primary,
                    '&.Mui-checked': {
                        color: palette.primary.main,
                    },
                }}
            />
            <Show condition={!!label}>{<Typography sx={{ display: 'flex', gap: 0.5 }}>{label}</Typography>}</Show>
        </Stack>
    );
};

export default DCPCheckbox;
